<template>
  <ul class="list pa0 ma0 mb3">
    <label v-for="gateway in gatewaysList" :key="gateway.id" :for="gatewayFor(gateway.id)">
      <li class="mb2 list-item">
        <input
          :id="gatewayFor(gateway.id)"
          v-model="gatewayId"
          type="radio"
          name="gatewayId"
          :value="gateway.id"
          class="mr3"
        >
        <span>{{ gateway.label }}</span>
      </li>
    </label>
  </ul>
</template>

<script>
export default {
  name: 'GatewaysSelector',
  props: {
    gateways: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      gatewayId: 0
    }
  },
  computed: {
    gatewaysList () {
      return this.gateways.map((gateway) => ({
        id: Number(gateway.data.id),
        label: gateway.label
      }))
    }
  },
  mounted () {
    const gateways = this.gatewaysList
    this.gatewayId = gateways.length ? gateways[0].id : 0
  },
  methods: {
    gatewayFor (id) {
      return 'gateway_' + id
    }
  }
}
</script>
