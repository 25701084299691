<template>
  <div v-if="isPartner === false">
    <slot />
  </div>
</template>

<script>
import s from '../store'

export default {
  name: 'CustomerBlock',
  data () {
    return {
      state: s.state
    }
  },
  computed: {
    isPartner () {
      return this.state.isPartner
    }
  },
  methods: {}
}
</script>
