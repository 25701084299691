<template>
  <label class="color-radio__button color-radio__button--active">
    <input
      class="color-radio__input"
      type="radio"
      name="colors"
      :class="{checked: color.id === modelValue.id}"
      :disabled="$parent.isMobile"
      @input="setColor"
    >
    <span
      class="color-radio__helper"
      :class="color.vSkuModificator === 'CC' ? 'color-radio__helper--last' : ''"
      :style="{'color': color.color}"
    >
      <span class="color-radio__hint" :class="color.vSkuModificator === 'CC' ? 'color-radio__hint--others' : ''">
        <span class="color-radio__label" :style="{'background-color': color.color}" />
        <span class="color-radio__title">{{ color.title }}</span>
        <span v-if="color.colorCode" class="color-radio__code">{{ color.colorCode }}</span>
        <span v-if="infoText" class="color-radio__info">{{ infoText }}</span>
      </span>
    </span>
    <span v-if="note && color.vSkuModificator === 'CC'" class="color-radio__note">{{ note }}</span>
  </label>
</template>

<script>
import { eventBus } from '../eventBus'
export default {
  name: 'ColorRadioButton',
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    color: {
      type: Object,
      required: true
    },
    infoText: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: Object,
      required: true
    },
    note: {
      type: String,
      default: null
    },
    isProduct: {
      type: Boolean,
      default: false,
      required: false
    },
    isMobile: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    setColor () {
      eventBus.$emit('color-changed', this.color)

      if (!this.isMobile) {
        this.$emit('change', this.color.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.color-radio {
  &__note {
    position: absolute;
    top: 0;
    right: -0.5rem;
  }

  &__button {
    cursor: pointer;
    position: relative;

    &--active .color-radio__helper {
      //box-shadow: 0px 0px 25px 0px rgba(255,255,255,0.4);
    }

    .color-radio__helper {
      border-radius: 50%;
      transition: 0.3s;
    }

    &:hover .color-radio__helper {
      color: #ffffff;

      &::before {
        border-color: #fff;
      }
    }

    .color-radio__helper--last {
      &:after {
        border: none;
        background: linear-gradient(to bottom right, red 10%, yellow 30%, limegreen 50%, blue 70%, purple 85%);
      }
    }

    &:hover .color-radio__hint {
      display: block;
    }
  }

  &__helper {
    width: 1.625rem;
    height: 1.625rem;
    display: flex;
    position: relative;

    &::before,
    &::after {
      content: '';
      top: 0;
      left: 0;
      width: 1.625rem;
      height: 1.625rem;
      display: block;
      border-radius: 50%;
      border: 2px solid transparent;
      margin: auto;
      position: absolute;
      transition: 0.3s;
    }

    &::after {
      width: 1rem;
      height: 1rem;
      background: currentColor;
      position: relative;
    }
  }

  &__input {
    display: none;

    &.checked + .color-radio__helper {

      &::before {
        border-color: #ffd600;
      }

      &--last::before {
        border-color: #fff;
      }
    }
  }

  &__hint {
    display: none;
    position: absolute;
    bottom: calc(100% + 1px);
    left: 50%;
    transform: translate(-50%, -0.5rem);
    background: #fff;
    padding: 0.5rem;
    color: #0a0d0f;
    z-index: 10;
    max-width: 125px;

    span {
      display: block;
      text-align: center;
      white-space: nowrap;
      line-height: 1;
      font-size: 0.85rem;
    }

    &--others {
      .color-radio__label {
        background: linear-gradient(to bottom right, red 10%, yellow 30%, limegreen 50%, blue 70%, purple 85%);
      }
    }

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border: solid;
      border-width: 0.5rem 0.5rem 0 0.5rem;
      border-color: #fff transparent transparent transparent;
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translate(-50%, 0.5rem);
    }
  }

  &__label {
    min-width: 5rem;
    width: 100%;
    height: 3rem;
    margin-bottom: 0.5rem;
  }

  &__info {
    word-break: normal;
    white-space: initial !important;
    margin-top: 8px;
    color: #b2b2b2;
  }
}
</style>
