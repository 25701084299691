<template>
  <color-radio-button v-bind="{color, value, modelValue, infoText}" :is-mobile="isMobile" :is-product="true" />
</template>

<script>
import store from '../store'
import ColorRadioButton from './ColorRadioButton.vue'

export default {
  name: 'ProductColor',
  components: {
    ColorRadioButton
  },
  props: {
    color: {
      type: Object,
      required: true
    },
    infoText: {
      type: String,
      required: false,
      default: null
    },
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    modelValue: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      store,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    isMobile: function () {
      return this.windowWidth < 920
    }
  },
  mounted () {
    window.onresize = () => {
      setTimeout(() => {
        this.windowWidth = window.innerWidth
      }, 300)
    }
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 60em) {
  .product__color {
    .color-radio__helper::before {
      display: none;
    }
    .color-radio__hint {
      display: none!important;
    }
  }
}
</style>
