<template>
  <div class="color-dropdown">
    <div class="color-dropdown__value" tabindex="0">
      <div
        class="color-dropdown__selected-color"
        :class="{ 'custom-color-selected': selectedColor.hasOwnProperty('vSkuModificator') && selectedColor.vSkuModificator === 'CC' }"
        :style="{ backgroundColor: selectedColor.color }"
      />
    </div>
    <div class="color-dropdown__list mt3 pa3">
      <slot />
      <p v-if="note" class="color-dropdown__note">
        * {{ note }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorDropdown',
  props: {
    selectedColor: {
      type: Object,
      required: true
    },
    note: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.color-dropdown {
  margin-left: auto;
  width: 4rem;
}

.color-dropdown__value {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: .5rem 38px .5rem .5rem;
  border-radius: 2px;
  border: solid 2px #333;
  outline: 0;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 12px;
    width: 11px;
    height: 6px;
    margin-top: -2px;
    transition: transform 0.3s;
    background-image: url("../../assets/select-arrow.svg");
  }

  &:focus {
    + .color-dropdown__list {
      visibility: visible;
      opacity: 1;
    }

    &::after {
      transform: rotate(-180deg);
    }
  }
}

.color-dropdown__selected-color {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.color-dropdown__list {
  position: absolute;
  z-index: 3;
  top: 100%;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  border-radius: 2px;
  border: solid 2px #333;
  background-color: #111;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;

  @media screen and (max-width: 30em) {
    left: 0;
    width: calc(100vw - 2rem);
  }
}

.color-dropdown__note {
  width: 100%;
}

.custom-color-selected {
  background: linear-gradient(to bottom right, red 10%, yellow 30%, limegreen 50%, blue 70%, purple 85%);
}
</style>
