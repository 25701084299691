<template>
  <div
    v-if="!_store.state.showMenuOverlay || !_store.isMobile()"
    class="bg-black ph3 ph5-l pv3 tc pointer-events-auto z-max relative dn"
    :class="{ 'db': isVisible }"
  >
    <div class="relative">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="sans-serif fw7 white infobar__text" v-html="_html" />

      <div class="absolute top-0 right-0">
        <button
          aria-label="close"
          class="bg-transparent pointer bw0 outline-0 pointer z-3 white o-50 glow"
          @click="dismiss"
        >
          <svg
            class="v-mid"
            width="12"
            height="12"
            style="fill:currentColor"
          ><path d="M1 2.5L2.5 1 6 4.5 9.5 1 11 2.5 7.5 6 11 9.5 9.5 11 6 7.5 2.5 11 1 9.5 4.5 6" /></svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import animate from 'animejs'
import store from '../store.js'

export default {
  props: {
    text: { type: String, default: '' }
  },
  data () {
    return {
      userTextCrc: '',
      textCrc: this.text
    }
  },
  computed: {
    _html: function () {
      return this.text.replaceAll('|', '<span class="infobar__divider">|</span>')
    },
    _store: () => store,
    isVisible: function () {
      return this.userTextCrc !== this.textCrc
    },
    infoBarStorageKey: function () {
      const locale = document.documentElement.getAttribute('lang')
      return 'infoBarCrc_' + locale
    }
  },
  mounted () {
    this.userTextCrc = localStorage.getItem(this.infoBarStorageKey)
    const headerNav = document.getElementById('js-header-nav')
    if (this.isVisible && this._store.isMobile()) {
      headerNav.style.paddingTop = '0'
    }
  },
  methods: {
    toggleContent: (val) => {
      const getProperty = (elem, cssProperty) => {
        const cssPropertyVal = window.getComputedStyle(elem, null).getPropertyValue(cssProperty).replace('px', '')
        return parseFloat(cssPropertyVal)
      }
      const mainContent = document.getElementById('js-main')
      const mainContentMt = getProperty(mainContent, 'margin-top')
      const header = document.getElementById('js-header')
      const headerMb = getProperty(header, 'margin-bottom')
      const headerNav = document.getElementById('js-header-nav')
      const headerNavPos = headerNav.getBoundingClientRect().top
      const calculatedMt = mainContentMt + headerMb + headerNavPos

      if (val === true) {
        animate({
          targets: mainContent,
          marginTop: calculatedMt,
          duration: 0,
          easing: 'easeOutCubic',
          complete: () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          }
        })
      } else {
        mainContent.removeAttribute('style')
        headerNav.removeAttribute('style')
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    dismiss () {
      this.userTextCrc = this.textCrc
      localStorage.setItem(this.infoBarStorageKey, this.textCrc)
      this.toggleContent(this.isVisible)
    },
    leave: (el, done) => {
      const menuBar = document.querySelector('[data-menubar]')
      const infoBarHeight = el.getBoundingClientRect().height

      animate({
        targets: menuBar,
        translateY: infoBarHeight * -1,
        duration: 400,
        easing: 'easeOutCubic',
        complete: () => {
          menuBar.style.transform = 'translateY(0)'
          done()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.infobar {
  &__divider {
    color: #ffd600;
    margin: 0 0.5rem;
  }
}
</style>
<style lang="scss" scoped>
  .infobar {
    &__text {
      margin: 0 1rem;
    }
  }
</style>
