<template>
  <div class="mb0 h3 relative z-1 cf">
    <ValidationProvider
      v-slot="{ errors }"
      mode="lazy"
      :vid="name"
      :name="label"
      :rules="validation"
      tag="div"
    >
      <select
        :id="name"
        v-model="currentValue"
        class="relative z-2 pt1 input-reset white bb bt-0 bl-0 br-0 b--white w-100 f5 outline-0 ba--transparent absolute top-0 br0 left-0 label-slide__input"
        :class="[errors.length ? 'b--red' : !currentValue ? 'b--white' : 'b--green']"
        :name="name"
        :disabled="disabled"
        @focus="handleFocus"
        @change="handleChange"
      >
        <option disabled selected value="">
          {{ blank }}
        </option>
        <option
          v-for="choice in choices"
          :key="choice.id"
          class="black"
          :value="choice.id"
        >
          {{ choice.title }}
        </option>
      </select>

      <label :for="name" class="absolute top-0 left-0 z-0 o-50 transition dib">
        {{ label }}
        <span> *</span>
      </label>

      <transition :css="false" @enter="errorEnter" @leave="errorLeave">
        <span v-if="errors.length" class="bg-near-black z-2 red absolute top-4 right-0 pa1">
          {{ errors[0] }}
        </span>
      </transition>
    </ValidationProvider>
  </div>
</template>

<script>
import anime from 'animejs'

export default {
  name: 'ViempieSelect',
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: String,
      default: '[]'
    },
    initialvalue: {
      type: [String, Number],
      default: null
    },
    label: {
      type: String,
      required: true
    },
    blank: {
      type: String,
      default: ''
    },
    scope: {
      type: String,
      default: ''
    },
    validation: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentValue: '',
      choices: JSON.parse(this.options)
    }
  },
  computed: {},
  mounted () {
    if (!this.initialvalue) return
    this.setCurrentValue(this.initialvalue)
  },
  methods: {
    errorEnter: (el, done) => handleErrorEnter(el, done),
    errorLeave: (el, done) => handleErrorLeave(el, done),
    handleFocus (event) {
      this.$emit('focus', event)
    },
    handleChange (event) {
      const value = event.target.value
      this.$emit('change', value)
      // this.setCurrentValue(value)
    },
    setCurrentValue (val) {
      if (val === this.currentValue) return
      this.currentValue = val
    }
  }
}

const handleErrorEnter = (el, done) => {
  anime({
    targets: el,
    opacity: [0, 1],
    translateX: [20, 0],
    duration: 400,
    easing: 'easeOutCubic',
    complete: done
  })
}

const handleErrorLeave = (el, done) => {
  anime({
    targets: el,
    opacity: 0,
    duration: 200,
    easing: 'easeOutCubic',
    complete: done
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
select{
  background-color: #111;
  option{
    color: white;
  }
}
label {
  font-size: 1rem;
  opacity: 0.9;
  transform: translateY(0);
  transition: all 200ms;
  position: absolute;
  top: -1.5rem;
}
</style>
