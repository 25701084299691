import anime from 'animejs'
import axios from 'axios'
import h from '../../helpers'
import s from '../../store'

const resetLabels = () => {
  const label = document.querySelector('.js-btn-label')
  const labelProcessing = document.querySelector('.js-btn-label-processing')
  const submit = document.querySelector('button[type=submit]:disabled')
  label.style.opacity = 1
  labelProcessing.style.opacity = 0
  submit.disabled = false
}

const handleLabelChange = (label, labelProcessing) => {
  anime({
    targets: label,
    duration: 250,
    opacity: 0
  })
  anime({
    targets: labelProcessing,
    duration: 250,
    opacity: 1
  })
}

const handleSuccess = obj => {
  if (s.debug) console.log('5. 🎉', obj)
  resetLabels()

  const locale = document.documentElement.getAttribute('lang')

  axios
    .get('/' + locale + '/api/v1/cart.json')
    .then(response => {
      const data = response.data.data[0]
      // Put the whole thing into the state
      s.setCartAction(data)
      s.toggleSideCartAction()
    })
}

const handleError = () => {
  const errorEl = document.querySelector('.js-request-error')
  errorEl.classList.toggle('active')
  setTimeout(() => {
    errorEl.classList.toggle('active')
  }, 1500)
}

export const handleSubmit = event => {
  event.preventDefault()

  const pInput = document.querySelector('input[id=\'inputPurchasableId\']')
  if (pInput === null) {
    return
  }
  const pId = pInput.value
  const psId = 'purchasables[' + pId + ']'

  const formEl = document.querySelector('.js-form-element')
  const form = formEl
  const colorCode = formEl.querySelector('input[name=\'' + psId + '[options][ralColorCode]\']:checked')
  if (s.debug) console.log(colorCode)

  const colorCodeManual = formEl.querySelector('input[name=\'' + psId + '[options][ralColorCodeManual]\']')
  if (s.debug) console.log(colorCodeManual)
  colorCodeManual.value = colorCodeManual.value.replace(/\s/g, '')
  if (colorCode === null) {
    const selectedEl = formEl.querySelector('input[name=\'' + psId + '[options][ralColorCode]\'][data-ralcode=\'' + colorCodeManual.value + '\']')
    if (selectedEl) {
      selectedEl.checked = true
    }
  }

  const data = h.getFormData(form, s.state.csrf)
  for (const pair of data.entries()) {
    if (pair[0] === psId + '[options][ralColorCodeManual]') {
      data.delete(pair[0])
    }
  }
  const label = document.querySelector('.js-btn-label')
  const labelProcessing = document.querySelector('.js-btn-label-processing')
  handleLabelChange(label, labelProcessing)

  const settings = {
    url: '/',
    data,
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }

  axios(settings).then(response => {
    response.data.success
      ? handleSuccess(response.data)
      : handleError(response.data)
  })
}

export const initCustomMadeLeftRightAdditions = () => {
  const pInput = document.querySelector('input[id=\'inputPurchasableId\']')
  if (pInput === null) {
    return
  }
  const pId = pInput.value
  const psId = 'purchasables[' + pId + ']'

  const selects = document.querySelectorAll('select[name="' + psId + '[options][glass]"], select[name="' + psId + '[options][vase]"]')
  for (let i = 0; i < selects.length; i++) {
    selects[i].addEventListener('change', disableOtherSide)
  }
}

const disableOtherSide = event => {
  const pInput = document.querySelector('input[id=\'inputPurchasableId\']')
  if (pInput === null) {
    return
  }
  const pId = pInput.value
  const psId = 'purchasables[' + pId + ']'

  const selectedEl = event.target
  const elementNames = document.querySelectorAll('select[name="' + psId + '[options][glass]"], select[name="' + psId + '[options][vase]"]')
  let otherElement = null
  for (let i = 0; i < elementNames.length; i++) {
    if (elementNames[i].name !== selectedEl.name) {
      otherElement = elementNames[i]
      if (otherElement.value !== 'none' && selectedEl.value !== 'none') {
        otherElement.value = 'none'
      }
    }
  }
}

export const updateCustomPrice = () => {
  const pInput = document.querySelector('input[id=\'inputPurchasableId\']')
  if (pInput === null) {
    return
  }
  const pId = pInput.value
  const psId = 'purchasables[' + pId + ']'

  const params = []
  const inputs = document.querySelectorAll('input[name="' + psId + '[options][length]"], input[name="' + psId + '[options][ralColorCode]"]:checked, input[name="' + psId + '[options][roundcorners]"]:checked')
  for (let i = 0; i < inputs.length; i++) {
    params.push(inputs[i].name + '=' + inputs[i].value.replace(/\s+/g, ''))
  }

  const selects = document.querySelectorAll('select[name="' + psId + '[options][depth]"], select[name="' + psId + '[options][glass]"], select[name="' + psId + '[options][vase]"]')
  for (let i = 0; i < selects.length; i++) {
    params.push(selects[i].name + '=' + selects[i].options[selects[i].selectedIndex].value)
  }

  const settings = {
    url: '/api/v1/customMade/price.json?' + params.join('&'),
    method: 'GET',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }

  axios(settings).then(response => {
    if (response.data.status) {
      document.querySelector('.js-custom-price').innerHTML = '&euro; ' + Math.round(response.data.price) + ',-'
      document.querySelector('input[name="' + psId + '[options][price]"]').value = response.data.price
    } else {
      const customPrice = document.querySelector('.js-custom-price')
      if (customPrice) customPrice.innerHTML = 'N/A'
    }
  })
}

const showAdditions = () => {
  const pInput = document.querySelector('input[id=\'inputPurchasableId\']')
  if (pInput === null) {
    return
  }
  const pId = pInput.value
  const psId = 'purchasables[' + pId + ']'

  const length = document.querySelector('input[name="' + psId + '[options][length]"]')
  const depth = document.querySelector('select[name="' + psId + '[options][depth]"]')
  const additions = document.querySelectorAll('.js-shelf-additions')

  if (Number(depth.options[depth.selectedIndex].value) === 29.5 && (Number(length.value) >= 50 && Number(length.value) <= 800)) {
    // check if already enabled
    for (let i = 0; i < additions.length; i++) {
      if (additions[i].disabled === false) {
        return false
      }
    }
    anime({
      targets: additions,
      duration: 250,
      opacity: 1,
      begin: function (anim) {
        for (let i = 0; i < anim.animatables.length; i++) {
          additions[i].disabled = false
        }
      }
    })
  } else {
    // check if already disabled
    for (let i = 0; i < additions.length; i++) {
      additions[i].value = 'none'
      if (additions[i].disabled === true) {
        return false
      }
    }
    anime({
      targets: [].slice.call(additions, 0).reverse(), // reversed array dom elements
      duration: 250,
      opacity: 0.2,
      complete: function (anim) {
        for (let i = 0; i < anim.animatables.length; i++) {
          additions[i].disabled = true
        }
      }
    })
  }
}

export const initCustomMadeListener = () => {
  let i
  const pInput = document.querySelector('input[id=\'inputPurchasableId\']')
  if (pInput === null) {
    return
  }
  const pId = pInput.value
  const psId = 'purchasables[' + pId + ']'
  let inputs = document.querySelectorAll('input[name="' + psId + '[options][length]"]')
  for (i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener('keyup', updateCustomPrice)
  }
  inputs = document.querySelectorAll('select[name="' + psId + '[options][depth]"], input[name="' + psId + '[options][roundcorners]"], select[name="' + psId + '[options][glass]"], select[name="' + psId + '[options][vase]"]')
  for (i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener('change', updateCustomPrice)
  }
  // show hide additions
  let input = document.querySelector('select[name="' + psId + '[options][depth]"]')
  if (input) {
    input.addEventListener('change', function (event) {
      showAdditions(event)
    })
  }
  input = document.querySelector('input[name="' + psId + '[options][length]"]')
  if (input) {
    input.addEventListener('keyup', function (event) {
      showAdditions(event)
    })
  }
}

export const initCustomMadeFooter = () => {
  const cookieBar = document.querySelector('.js-cookie-bar')
  const customMadeFooter = document.querySelector('.js-custom-made-footer')

  if (cookieBar && customMadeFooter) {
    const offset = cookieBar.clientHeight
    const transformStyle = customMadeFooter.style.transform
    const translateY = transformStyle.replace(/[^\d.]/g, '')
    const translateYVal = +translateY

    if (cookieBar.classList.contains('dn')) {
      anime({
        targets: customMadeFooter,
        translateY: [-translateYVal, 0],
        duration: 350,
        easing: 'easeOutCubic'
      })
    } else {
      anime({
        targets: customMadeFooter,
        translateY: [0, -offset],
        duration: 350,
        easing: 'easeOutCubic'
      })
    }
  }
}
