<template>
  <div class="show-more__wrapper">
    <div
      class="product__show-more"
      :class="{'product__show-more_offset': typeof $slots.default === 'undefined'}"
      @click.prevent="isPopupShown = true"
    >
      <slot>{{ _translate('More information') }}</slot>
    </div>
    <popup-component
      v-if="isPopupShown"
      :title="title"
      :html="description"
      @close="close"
    />
  </div>
</template>

<script>
import store from '../store'
import PopupComponent from './PopupComponent.vue'

export default {
  name: 'MoreInformation',
  components: { PopupComponent },
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      store,
      isPopupShown: false
    }
  },
  methods: {
    close () {
      this.isPopupShown = false
    },
    _translate (key) {
      return store._translates(key)
    }
  }
}
</script>

<style scoped lang="scss">
  .show-more__wrapper {
    display: flex;
  }

  .product {
    &__show-more {
      font-family: FuturaPT, sans-serif;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #b2b2b2 !important;
      text-decoration: underline;
      transition: 0.3s;
      margin: auto 0;

      &_offset {
        margin-top: 2rem;
      }

      &:hover {
        color: #fff !important;
        text-decoration: none;
      }
    }
  }
</style>
