<template>
  <div
    :class="{
      'product__corner_shelf_option': true,
      'product__corner_shelf_option_active': isActive,
      'product__corner_shelf_option_disabled': disabled,
      'product__corner_shelf_option_static': isStatic
    }"
    :style="'background-image: url(' + option.cover + ');'"
  >
    <div class="product__corner_shelf_option-cost">
      {{ option.vPriceModificator | toEur }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCornerShelfOption',
  props: {
    option: {
      type: Object,
      required: true
    },
    disabled: {
      type: Number,
      default: 0
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isStatic: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.product {
  &__corner_shelf_option {
    width: 100%;
    height: auto;
    border-radius: 2px;
    border: solid 2px #333333;
    padding: 1.75rem 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 14px;
    justify-content: flex-end;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 1rem;
    background-position: bottom left 1rem;
    background-repeat: no-repeat;
    background-size: auto 62px;
    user-select: none;

    &-image {
      border-radius: 2px;
      border: solid 2px #333333;
      width: 100px;
      margin-bottom: 1rem;
      transition: 0.3s;
    }

    &-title {
      line-height: 1.5;
      color: #fff;
      transition: 0.3s;
    }

    &-cost {
      font-size: 14px;
    }

    &_disabled {
      opacity: .2;
    }

    &_static {
      cursor: default;
    }

    &:hover:not(.product__corner_shelf_option_disabled) {
      border-color: #616161;

      .expand__option-title {
        color: #fff !important;
      }
    }

    &_active {
      border-color: #ffd600 !important;

      &-title {
        color: #fff;
      }
    }
  }
}
</style>
