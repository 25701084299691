<template>
  <div class="product__lightning">
    <expand-horizontal-option
      v-for="led in filtered_leds"
      :key="led.id"
      :class="{
        expand__option_active: isActive(led.id, 'v_led'),
        expand__option_disabled: disabled && !isActive(led.id, 'v_led'),
      }"
      :title="led.title"
      :image="image(led)"
      :cost="led.vPriceModificator"
      @click.native="changeValue(led, true)"
    />
    <div
      v-if="selectedLed && selectedLed.vAvailPosition && Object.keys(selectedLed.vAvailPosition).length"
      class="product__lightning-section"
    >
      <div class="product__lightning-section-title">
        {{ _translate('Cable position') }}
      </div>
      <div class="product__lightning-select-positions">
        <expand-horizontal-option
          v-for="(position, i) in selectedLed.vAvailPosition"
          :key="i"
          :title="_translate(position.label)"
          :image="positionImage(position.label)"
          :cutout="true"
          :short="true"
          class="product__lightning-select-position"
          :class="{
            'product__lightning-select-position_left': position.value === 'left',
            'product__lightning-select-position_active': position.value === additionInfo.position,
          }"
          :info="selectedLed.vDescription"
          @click.native="changePosition(position.value)"
        />
      </div>
    </div>
    <div
      v-if="selectedLed && selectedLed.id !== 58493"
    >
      <div class="product__lightning-section-title">
        {{ _translate('Control system') }}
      </div>
      <div class="product__lightning-select-positions">
        <expand-horizontal-option
          v-for="control_system in filtered_control_system"
          :key="control_system.id"
          class="expand__option_left"
          :class="{
            expand__option_active: isActive(control_system.id, 'v_control_system'),
          }"
          :title="control_system.title"
          :cost="control_system.vPriceModificator"
          :info="control_system.description"
          @click.native="changeCostPrice('v_control_system', control_system)"
        />
      </div>
    </div>
    <div
      v-if="selectedLed && selectedLed.id !== 58493 && selectedControlSystem !== 147802"
      class="product__lightning-section"
    >
      <div class="product__lightning-section-title">
        {{ _translate('Choose system') }}
      </div>
      <div class="product__lightning-grid">
        <radio-button-extended
          v-for="lighting_plan in filtered_lighting_plan"
          :key="lighting_plan.id"
          :title="lighting_plan.title"
          :model-value="selectedLightingPlan"
          :info="lighting_plan.description"
          :info-title="lighting_plan.title"
          :value="lighting_plan.id"
          @click.native="changeCostPrice('v_lighting_plan', lighting_plan)"
        />
      </div>
    </div>
    <div />
  </div>
</template>

<script>
import ExpandHorizontalOption from './ExpandHorizontalOption.vue'
import RadioButtonExtended from './RadioButtonExtended.vue'
import store from '../store'

export default {
  name: 'ProductLightning',
  components: {
    ExpandHorizontalOption,
    RadioButtonExtended
  },
  props: {
    disableAllAround: {
      type: Boolean,
      default: false
    },
    disableTopAndBottom: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Number,
      default: 0
    },
    product: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    state: store.state,
    additionInfo: {
      position: null
    }
  }),
  computed: {
    filtered_leds () {
      const filterList = this.state.filters.v_led.filter((item) => {
        if (this.disableAllAround && item.uid === '36a359fa-4b33-4d85-93b7-f260bdba42b2') {
          return false
        }

        if (this.disableTopAndBottom && (item.id === 257993 || item.id === 244681)) {
          return false
        }

        const requiredMinimumDepth = item.vMinDepth <= this.state.selectedOptions.v_depth.vDepth
        const requiredMinimumLength = item.vMinLen <= this.state.selectedOptions.v_len.vMinLen

        return requiredMinimumDepth && requiredMinimumLength
      })

      if (Object.prototype.hasOwnProperty.call(store.state.selectedOptions, 'v_led')) {
        if (!filterList.find((item) => item.id === this.state.selectedOptions.v_led.id)) {
          this.changeValue(this.state.filters.v_led.find((item) => item.id === this.state.defaults.v_led))
        }
      }

      return filterList
    },
    filtered_control_system () {
      const filterList = this.state.filters.v_control_system
      if (Object.prototype.hasOwnProperty.call(store.state.selectedOptions, 'v_control_system')) {
        if (!filterList.find((item) => item.id === this.state.selectedOptions.v_control_system.id)) {
          this.changeCostPrice('v_control_system', this.state.filters.v_control_system.find((item) => item.id === this.state.defaults.v_control_system))
        }
      }

      return filterList
    },
    filtered_lighting_plan () {
      const filterList = this.state.filters.v_lighting_plan
      if (Object.prototype.hasOwnProperty.call(store.state.selectedOptions, 'v_lighting_plan')) {
        if (!filterList.find((item) => item.id === this.state.selectedOptions.v_lighting_plan.id)) {
          this.changeCostPrice('v_lighting_plan', this.state.filters.v_lighting_plan.find((item) => item.id === this.state.defaults.v_lighting_plan))
        }
      }

      return filterList
    },
    selectedLed () {
      if (Object.prototype.hasOwnProperty.call(this.state.selectedOptions, 'v_led')) {
        if (!this.state.selectedOptions.v_led.vAvailPosition[0]) {
          store.setAdditionInfo('v_led', null)
        }

        return this.state.selectedOptions.v_led
      }
      return null
    },
    selectedControlSystem () {
      if (!Object.prototype.hasOwnProperty.call(store.state.selectedOptions, 'v_control_system')) {
        return this.filtered_control_system[0].id
      } else {
        return store.state.selectedOptions.v_control_system.id
      }
    },
    selectedLightingPlan () {
      // Set default light plan
      if (!Object.prototype.hasOwnProperty.call(store.state.selectedOptions, 'v_lighting_plan')) {
        return this.filtered_lighting_plan[0].id
      } else {
        return store.state.selectedOptions.v_lighting_plan.id
      }
    }
  },
  beforeMount () {
    if (this.selectedLed && this.selectedLed.vAvailPosition.length) {
      this.changePosition(this.selectedLed.vAvailPosition[0].value)
    } else {
      this.changePosition('left')
    }
  },
  methods: {
    changeValue (led, isClick = false) {
      if (isClick) {
        const relatedLedsMapped = []
        for (const relatedLed of this.product.relatedProducts) {
          relatedLedsMapped[relatedLed.vledCategory.slug] = relatedLed
        }

        const needle = led.slug
        if (typeof relatedLedsMapped[needle] !== 'undefined') {
          const relatedLedMapped = relatedLedsMapped[needle]
          let windowSearch = window.location.search.replace('?', '')

          if (windowSearch && windowSearch.includes('v_led=')) {
            const replacement = typeof led.id !== 'undefined' ? `v_led=${led.id}` : ''
            windowSearch = windowSearch.replace(/v_led=\d*/, replacement)
          }

          window.location.href = `/${this.state.current_lang}/` +
              relatedLedMapped.vmodelCategory[relatedLedMapped.vmodelCategory.length - 1] +
              `/${relatedLedMapped.slug}--${store.getSelectedOption('v_color').slug}?${windowSearch}`

          return
        }
      }

      if (!this.disabled) {
        this.$emit('costChange', 'v_led', {
          ...led,
          cablePosition: this.additionInfo.position
        })
      }
    },
    changeCostPrice (handle, item) {
      this.$emit('costChange', handle, item)
    },
    image (led) {
      if (led.cover.length) return led.cover[0]
      return ''
    },
    isActive (id, handle) {
      return Object.prototype.hasOwnProperty.call(this.state.selectedOptions, handle) && this.state.selectedOptions[handle].id === id
    },
    positionImage (position) {
      const url = ''
      if (!this.selectedLed) {
        return url
      }
      const vImage = this.selectedLed[`vImage${position}`]
      if (vImage && vImage[0]) {
        return vImage[0]
      }
    },
    changePosition (position) {
      this.additionInfo.position = position
      store.setAdditionInfo('v_led', this.additionInfo)
    },
    formatLightingPlanTitle (item) {
      const price = this.$options.filters.toEur(item.vPriceModificator)
      return `${item.title} ${price}`
    },
    infoTitle (title) {
      return `${this._translate('Light plan')} '${title}'`
    },
    _translate (key) {
      return store._translates(key)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .expand__option_disabled {
    opacity: 0.2;
  }
}

.product__lightning {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-select-positions {
    display: flex;
    gap: 0.5rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &_full {
      flex-direction: column;
      height: auto;
    }
  }

  &-select-position {
    flex-basis: 50%;
    background-position: right bottom 0 !important;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 2.2rem;
    padding-left: 1rem;

    &_full {
      flex-basis: initial;
    }

    &_left {
      background-position: left bottom 0 !important;
      justify-content: flex-start;
    }

    &:hover {
      border-color: #616161;

      ::v-deep {
        .expand__option-title {
          color: #fff !important;
        }
      }
    }

    &_active {
      border-color: #ffd600;

      &:hover {
        border-color: #ffd600;
      }

      ::v-deep {
        .expand__option-title {
          color: #fff !important;
        }
      }
    }
  }

  &-section {
    &-count {
      display: flex;
      gap: 24px;
      margin-bottom: 1rem;

      div {
        flex-basis: 100%;
      }
    }

    &-title {
      margin: 0 0 1rem;
      padding-top: 1rem;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .radio__button {
      white-space: nowrap;
    }
  }
}

.filter {
  &__note {
    margin-top: 2rem;
    margin-left: 1rem;
  }

  .mw300 {
    max-width: 300px;
  }

  &__reset {
    line-height: 1.5rem;
    cursor: pointer;
    margin-top: 0.3rem;
  }

  &__show-more {
    line-height: 1.5rem;
    cursor: pointer;
    margin-top: 1rem;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: Ladislav, sans-serif;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 60em) {
      margin-bottom: 1.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__close {
    display: flex;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    padding: 10px;
    background-color: #fff;
    border-radius: 50%;
    border: none;
  }

  &__options {
    &.color {
      display: flex;
      gap: 1rem;
      width: 9rem;
      flex-wrap: wrap;

      @media screen and (max-width: 60em) {
        width: 100%;
        padding: 0 0 0 1rem;
      }
    }
  }
}
</style>
