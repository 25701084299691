<template>
  <transition :css="false" @enter="enter" @leave="leave">
    <div
      class="sidecart fixed z-999 right-0 bg-black-50"
      @click.self="toggle"
    >
      <div class="header__button header__button_showed" @click="toggle">
        <button class="fl fn-l w2 h2 tc pa1 br-100 bw0 outline-0 bg-white black pointer cool-shadow">
          <span class="dib" style="transform: translateY(-1px);">
            <svg
              width="12"
              height="12"
              class="v-mid"
              style="fill: currentcolor;"
            >
              <path d="M1 2.5L2.5 1 6 4.5 9.5 1 11 2.5 7.5 6 11 9.5 9.5 11 6 7.5 2.5 11 1 9.5 4.5 6" /></svg>
          </span>
        </button>
      </div>
      <div
        class="sidecart__body absolute right-0 top-0 w-100 mw6 overflow-hidden bg-near-black cool-shadow-left
        cart"
      >
        <div class="product-link__wrapper">
          <a :href="shelvesUrl" class="product__back">
            {{ _translate('Shop further') }}
          </a>
        </div>
        <div v-if="store.state.cart" class="tr pb4 cart__inner" style="height: 100%;overflow-y: auto">
          <div v-if="cartItems.colorSamples.length > 0" class="cart__items">
            <p class="cart__items-title serif">
              {{ _translate('Samples') }}
            </p>
            <side-cart-custom-samples-pack
              :samples="cartItems.colorSamples"
            />
          </div>
          <div v-if="cartItems.colorSamplePack.length > 0" class="cart__items">
            <sidecart-sample-pack-item
              v-for="item in cartItems.colorSamplePack"
              :id="item.id"
              :key="item.id"
              :purchasable-id="item.purchasableId"
              :color="item.color"
              :options="item.options"
              :options-signature="item.optionsSignature"
              :qty="item.qty"
              :price="item.price"
              :sale-price="item.salePrice"
              :btwob="btwob"
              :variant="item.variant"
            />
          </div>
          <div v-if="cartItems.main.length > 0" class="cart__items">
            <sidecart-main-item
              v-for="item in cartItems.main"
              :id="item.id"
              :key="item.id"
              :purchasable-id="item.purchasableId"
              :color="item.color"
              :options="item.options"
              :options-signature="item.optionsSignature"
              :qty="item.qty"
              :price="item.price"
              :sale-price="item.salePrice"
              :btwob="btwob"
              :variant="item.variant"
            />
          </div>
          <accessories-component
            v-if="store.getComputedAccessories().length"
            :accessories="store.getComputedAccessories()"
          />
        </div>

        <div class="pb5 pb0-l absolute bottom-0 left-0 w-100 tr cool-shadow-up bg-near-black cart__button">
          <p v-if="store.state.cart" class="ph3 ph5-l pb4 serif f3 total">
            <small
              v-for="(item, i) in groupedDiscounts"
              :key="i"
              class="gray total__hint"
            >
              {{ item.name }}: {{ item.amount|toEur }}
            </small>

            {{ totalLabel }} {{ store.state.cart.totalPrice|toEur }}
          </p>

          <a
            :href="checkoutUrl"
            class="add-to-cart btn db tc black bg-yellow w-100 pa4 sans-serif fw7 f4"
          >{{ checkoutButton }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import anime from 'animejs'
import SideCartMainItem from './SideCartMainItem.vue'
import SideCartCustomSamplesPack from './SideCartCustomSamplesPack.vue'
import SideCartSamplePackItem from './SideCartSamplePackItem.vue'
import AccessoriesComponent from '../AccessoriesComponent.vue'
import store from '../../store'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { translatesMixin } from '../../mixins/translatesMixin'

let scrollElement = document.body

export default {
  name: 'SideCart',
  components: {
    SideCartCustomSamplesPack,
    'sidecart-main-item': SideCartMainItem,
    'sidecart-sample-pack-item': SideCartSamplePackItem,
    AccessoriesComponent
  },
  mixins: [translatesMixin],
  props: {
    checkoutUrl: {
      type: String,
      required: true
    },
    shelvesUrl: {
      type: String,
      required: true
    },
    discountLabel: {
      type: String,
      default: ''
    },
    totalLabel: {
      type: String,
      default: ''
    },
    vatNotice: {
      type: String,
      default: ''
    },
    checkoutButton: {
      type: String,
      default: ''
    },
    btwob: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      privateState: {
        isLoaded: false
      },
      store
    }
  },
  computed: {
    cartItems () {
      const cartItems = {
        colorSamplePack: [],
        colorSamples: [],
        main: []
      }
      for (const lineItem of Object.values(store.getCartItems())) {
        switch (lineItem.type) {
          case 'colorSamples':
            cartItems.colorSamples.push(lineItem)
            break
          case 'colorSamplePack':
            cartItems.colorSamplePack.push(lineItem)
            break
          default:
            cartItems.main.push(lineItem)
            break
        }
      }

      return cartItems
    },
    groupedDiscounts: function () {
      const discounts = []
      if (!Array.isArray(store.state.cart.adjustments.discount)) {
        return discounts
      }
      // Group adjustments by name, excluding VAT
      for (let i = 0; i < store.state.cart.adjustments.discount.length; i++) {
        if (store.state.cart.adjustments.discount[i].lineItemId === null) {
          continue
        }
        const dName = store.state.cart.adjustments.discount[i].name
        const discountObj = {
          name: dName,
          amount: store.state.cart.adjustments.discount[i].amount
        }
        const index = discounts.findIndex((element) => { return element.name === dName })
        if (index >= 0) {
          discounts[index].amount += discountObj.amount
        } else {
          discounts.push(discountObj)
        }
      }
      // Show VAT
      for (let i = 0; i < store.state.cart.adjustments.discount.length; i++) {
        if (store.state.cart.adjustments.discount[i].lineItemId !== null) {
          continue
        }
        const discountObj = {
          name: store.state.cart.adjustments.discount[i].name,
          amount: store.state.cart.adjustments.discount[i].amount
        }
        discounts.push(discountObj)
      }
      return discounts
    }
  },
  updated () {},
  mounted () {
    setTimeout(() => {
      scrollElement = document.querySelector('.cart__inner')
      disableBodyScroll(scrollElement)
    }, 100)
  },
  beforeDestroy () {
    scrollElement = document.querySelector('.cart__inner')
    enableBodyScroll(scrollElement)
  },
  methods: {
    getTitle () {
      return ''
    },
    toggle: () => store.toggleSideCartAction(),
    enter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        translateX: [20, 0],
        duration: 400,
        easing: 'easeOutCubic',
        complete: done
      })

      const children = el.querySelectorAll('[data-child]')
      anime({
        targets: children,
        opacity: [0, 1],
        translateX: [100, 0],
        duration: 300,
        easing: 'easeOutCubic',
        delay: function (el, i) {
          return 40 + i * 20
        }
      })
    },
    leave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        translateX: 20,
        duration: 300,
        easing: 'easeOutCubic',
        complete: done
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .product-link__wrapper {
    text-align: right;
    padding-right: 6rem;

    @media screen and (max-width: 60em) {
      padding-right: 1rem;
    }
  }

  .add-to-cart {
    color: #0a0d0f !important;
  }

  .sidecart {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    &__body {
      top: 0;
      right: 0;
      bottom: 0;

      @media screen and (max-width: 60em) {
        width: 100%;
      }
    }
  }

  .header__button {
    position: absolute;
    top: 48px;
    right: 16px;
    z-index: 1;

    @media screen and (min-width: 60em) {
      right: 72px;
    }
  }

  .cart {
    padding: 144px 0;

    @media screen and (max-width: 60em) {
      padding-bottom: 0;
      padding-top: 8rem;
    }

    &__items {
      padding: 0 4.5rem 0 4rem;

      &:last-child {
        padding-bottom: 7rem;
      }

      &-title {
        font-size: 40px;
      }

      @media screen and (max-width: 60em) {
        padding: 0 1rem;
      }
    }

    &__button {
      @media screen and (max-width: 60em) {
        padding-bottom: 0;

        .btn {
          padding: 1.5rem;
          line-height: 1.2;
        }

        .total {
          padding-bottom: 2.5rem;
        }
      }
    }

    &__inner {
      @media screen and (max-width: 60em) {
        padding-bottom: 10rem;
      }
    }

    .total {
      display: block;
      small {
        display: block;
        font-size: 1rem;
      }
    }
  }
</style>
