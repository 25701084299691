<template>
  <div id="color-samples" class="color-samples-products">
    <div v-for="(variants, colorName) in colorSamplesVariants" :key="colorName" class="color-samples-variants">
      <h3 class="color-samples-variants-title serif">
        {{ _translate(colorName) }}
      </h3>
      <div class="color-samples-variants-list">
        <color-samples-variant v-for="variant in variants" :key="variant.id" :variant="variant" />
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store'
import ColorSamplesVariant from './ColorSamplesVariant.vue'
import { translatesMixin } from '../../mixins/translatesMixin'

export default {
  name: 'ColorSamplesProducts',
  components: {
    ColorSamplesVariant
  },
  mixins: [translatesMixin],
  props: {
    products: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      state: store.state,
      isHovering: false
    }
  },
  computed: {
    filteredProducts () {
      // Clone object to avoid parent property mutation
      const filteredCarry = { ...this.products }

      if (this.state.selectedOptions.sampleColor !== undefined) {
        for (const [colorName, variants] of Object.entries(filteredCarry)) {
          filteredCarry[colorName] = variants.filter(() => this.state.selectedOptions.sampleColor.name === colorName)
        }
      }

      if (this.state.selectedOptions.sampleMaterial !== undefined) {
        for (const [colorName, variants] of Object.entries(filteredCarry)) {
          filteredCarry[colorName] = variants.filter((variant) => variant.finishType !== null && this.state.selectedOptions.sampleMaterial.handle === variant.finishType.slug)
        }
      }

      if (this.state.selectedOptions.searchValue !== undefined) {
        for (const [colorName, variants] of Object.entries(filteredCarry)) {
          const searchValue = this.state.selectedOptions.searchValue.toLowerCase()
          filteredCarry[colorName] = variants.filter((variant) =>
            colorName.toLowerCase().includes(searchValue) ||
              variant.title.toLowerCase().includes(searchValue) ||
              variant.title.toLowerCase().replace(/ /g, '').includes(searchValue) ||
              (variant.finishType && variant.finishType.title.toLowerCase().includes(searchValue)) ||
              (variant.colorCode && variant.colorCode.toLowerCase().includes(searchValue))
          )
        }
      }

      return filteredCarry
    },
    colorSamplesVariants () {
      const result = {}

      for (const [colorName, variants] of Object.entries(this.filteredProducts)) {
        if (variants.length > 0) {
          result[colorName] = variants
        }
      }

      return result
    }
  }
}
</script>
