<template>
  <label
    class="radio__button"
    :class="{short}"
  >
    <input
      class="radio__input"
      type="radio"
      :checked="isChecked"
      :value="value"
      @change="$emit('change', value);"
    >
    <span class="radio__helper">
      {{ _translate(title) }}
      <span
        v-if="isChecked && isClearable"
        class="radio__clear"
        @click.prevent="$emit('clear')"
      >x</span>
      <span
        v-if="isNew"
        class="radio__indicator_new"
      >new</span>
    </span>
  </label>
</template>

<script>
import store from '../store'

export default {
  name: 'RadioButton',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    isClearable: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    modelValue: {
      type: [String, Number],
      default: null
    },
    short: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isChecked () {
      return this.modelValue === this.value
    }
  },
  methods: {
    _translate (key) { return store._translates(key) }
  }
}
</script>

<style scoped lang="scss">
.radio {
  &__indicator_new,
  &__clear {
    margin-left: 0.5rem;
    color: #ffd600;
  }

  &__clear {
    margin-left: 1rem;
  }

  &__button {
    cursor: pointer;
    margin-bottom: 0.5rem;
    width: 100%;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover .radio__helper {
      color: #ffffff;

      &::before {
        border-color: transparent;
        background: #ffd700;
      }
    }

    &.short {
      width: 40%;

      @media screen and (max-width: 60em) {
        width: 100%;
      }
    }

    @media screen and (max-width: 60em) {
      width: 100%;
      margin-bottom: 0;
      padding: 12px 0;
      text-align: center;
    }
  }

  &__helper {
    height: 1.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    transition: 0.3s;

    &::before {
      content: '';
      margin-right: 1rem;
      flex-shrink: 0;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background: transparent;
      border: 0.125rem solid #b2b2b2;
      transition: 0.3s;
    }

    @media screen and (max-width: 60em) {
      justify-content: center;

      &::before {
        content: none;
      }
    }
  }

  &__input {
    display: none;

    &:checked + .radio__helper {
      color: #ffffff;

      &::before {
        border-color: transparent;
        background: #ffd700;
      }
    }
  }
}
</style>
