import store from '../store'

export const sampleLimiterMixin = {
  data () {
    return {
      sampleLimit: 3,
      cart: store.state.cart
    }
  },
  methods: {
    isCartAndSelectedAboveLimit: function () {
      return this.getNumberSamplesCartWithSelected() > this.sampleLimit
    },
    isSelectedSamplesLimitReached: function () {
      return (this.sampleLimit - Object.values(store.state.selectedSamples).length) === 0
    },
    isTotalSamplesLimitReached: function () {
      return this.getNumberSamplesCartWithSelected() >= this.sampleLimit
    },
    getNumberSamplesCart: function () {
      return store.state.samplesInCart
    },
    incrementSamples: function () {
      if (store.state.samplesInCart <= this.sampleLimit) {
        ++store.state.samplesInCart
      }
    },
    decreaseSample: function () {
      if (store.state.samplesInCart > 0) {
        --store.state.samplesInCart
      }
    },
    getNumberSamplesCartWithSelected: function () {
      return this.getNumberSamplesCart() + Object.values(store.state.selectedSamples).length
    }
  },
  computed: {
    otherProductTypesInCart () {
      const cart = this.state.cart
      if (!cart || !Object.prototype.hasOwnProperty.call(cart, 'lineItems')) {
        this.state.samplesInCart = 0
        this.state.sampleProductsInCart = 0

        return false
      }

      const nonSamplesInTheCart = Object.values(cart.lineItems).filter(item => !['colorSamples', 'colorSamplePack'].includes(item.type))
      return nonSamplesInTheCart.length > 0
    }
  }
}
