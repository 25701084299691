<template>
  <div class="product__customization">
    <div class="product__customization-title">
      <div class="product__customization-title-text">
        {{ title }}
      </div>
      <div v-if="description" class="product__customization-info" @click="onInfoClick" />
      <div v-if="notification" class="product__customization-notification">
        {{ notification }}
      </div>
    </div>
    <slot />
    <popup-component
      v-if="description && isPopupShown"
      :title="title"
      :html="description"
      @close="close"
    />
  </div>
</template>

<script>
import PopupComponent from './PopupComponent.vue'

export default {
  name: 'ProductCustomization',
  components: { PopupComponent },
  props: {
    title: {
      type: String,
      required: true
    },
    descriptionTitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    notification: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isPopupShown: false
    }
  },
  methods: {
    onInfoClick () {
      if (this.disabled) {
        return
      }
      this.isPopupShown = true
    },
    close () {
      this.isPopupShown = false
    }
  }
}
</script>

<style scoped lang="scss">
.product__customization-title {
  font-family: Ladislav, serif;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0 0 1.25rem;
  position: relative;
  display: flex;
  align-items: center;

  &-text {
    white-space: nowrap;
  }
}
.product__customization-notification {
  margin-left: 2rem;
  font-size: 1rem;
  font-family: "FuturaPT", sans-serif;
  color: #ffd600;
}
.product__customization + .product__customization {
  border-top: 1px solid #333333;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}
.product__customization-info {
  margin-left: 10px;
  opacity: 0.5;
  background-image: url("/build/img/info.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
</style>
