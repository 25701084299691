<template>
  <div class="mb5 side-item">
    <h3 class="white serif side-item__title">
      <span v-if="!privateState.purchasable" class="faux-text w-60">&nbsp;</span>
      <transition :css="false" @enter="enter" @leave="leave">
        <span v-if="privateState.purchasable">
          <span>{{ privateState.purchasable.title.split('|')[0] }}</span>
        </span>
      </transition>
    </h3>

    <h4
      v-if="typeof _options.Length !== 'undefined' && typeof _options.Depth !== 'undefined'"
      class="serif f3 side-item__subtitle"
    >
      {{ computedSize }}
    </h4>

    <p class="side-item__options">
      <transition :css="false" @enter="enter" @leave="leave">
        <span v-if="_options">
          <span v-for="(key, option) in filteredOptions" :key="option" class="side-item__option">
            {{ computedOption(key, option) }}
          </span>
        </span>
      </transition>
    </p>

    <div class="flex">
      <div class="w-75">
        <transition :css="false" @enter="enter" @leave="leave">
          <form
            v-if="privateState.purchasable"
            ref="form"
            method="post"
            action
            accept-charset="UTF-8"
            @submit.prevent="updateQty"
          >
            <input type="hidden" name="action" value="commerce/cart/update-cart">
            <input
              v-for="(value, index) in options"
              :key="index"
              type="hidden"
              :name="'lineItems['+id+'][options][' + index + ']'"
              :value="value"
            >
            <input type="hidden" :name="'lineItems['+id+'][id]'" :value="id">
            <input type="hidden" :name="'lineItems['+id+'][qty]'" :value="privateState.qty">

            <button class="bw0 o-70 glow bg-transparent yellow pointer outline-0" @click="decrement">
              <svg width="12" height="12" style="fill:currentColor">
                <path d="M0 7h12V5H0z" />
              </svg>
            </button>

            <input
              v-model="privateState.qty"
              type="text"
              class="serif bw0 white w2 tc"
              readonly
            >

            <button class="bw0 o-70 glow bg-transparent yellow pointer outline-0" @click="increment">
              <svg width="12" height="12" style="fill:currentColor">
                <path d="M0 5h5V0h2v5h5v2H7v5H5V7H0" />
              </svg>
            </button>
          </form>
        </transition>
      </div>

      <div class="w-25">
        <span v-if="!privateState.purchasable" class="faux-text w-40">&nbsp;</span>
        <transition :css="false" @enter="enter" @leave="leave">
          <p v-if="privateState.purchasable" class="serif">
            <!--            <span v-if="salePrice !== price">-->
            <!--              <span v-if="btwob === 'true'">{{ salePrice * privateState.qty|toEur }}</span>-->
            <!--              <span v-else class="yellow">-->
            <!--                <s class="white">{{ price * privateState.qty|toEur }}</s>-->
            <!--                {{ salePrice * privateState.qty|toEur }}-->
            <!--              </span>-->
            <!--            </span>-->
            <span>{{ salePrice * privateState.qty|toEur }}</span>
          </p>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs'
import axios from 'axios'
import h from '../../helpers'
import s from '../../store'

export default {
  name: 'SideCartItemMain',
  props: {
    id: { type: Number, required: true },
    purchasableId: { type: [String, Number], default: '' },
    qty: { type: Number, default: 0 },
    price: { type: Number, default: 0 },
    salePrice: { type: Number, default: 0 },
    variant: { type: Object, default: () => null },
    options: { type: Object, default: null },
    btwob: { type: String, default: '' }
  },
  data () {
    return {
      privateState: {
        qty: this.qty,
        purchasable: null
      },
      sharedState: s.state
    }
  },
  computed: {
    computedSize () {
      let length = this._options.Length.split(',')[0]

      if (typeof this.options['Length selected'] !== 'undefined') {
        length = `${this.options['Length selected']} cm`
      }

      return `${length} X ${this.options.Depth.split(',')[0]}`
    },
    filteredOptions () {
      const options = { ...this.options }
      const hiddenOptions = ['Depth', 'Length', '=computed=', 'Length selected', 'ralColorSku', 'Color selected', 'Finish_UID']

      hiddenOptions.forEach((key) => delete options[key])
      return options
    },
    _options () {
      const options = {}
      for (const key of Object.keys(this.options)) {
        if (key === 'Room-divider') {
          options.RoomDivider = this.options[key]
        } else if (key === 'Cut-outs') {
          options.CutOuts = this.options[key]
        } else if (key !== '=computed=') {
          options[key] = this.options[key]
        }
      }
      return options
    }
  },
  mounted () {
    if (this.variant !== null) {
      this.privateState.purchasable = this.variant
    } else {
      const locale = document.documentElement.getAttribute('lang')

      axios
        .get(`/${locale}/api/v1/purchasables/${this.purchasableId}.json`)
        .then(response => {
          this.privateState.purchasable = response.data
        })
    }
  },
  methods: {
    computedOption (value, key) {
      let computedTitle = key
      if (key === 'Led') {
        computedTitle = 'Lighting'
      }
      const values = value.split(', €')
      const cost = typeof values[1] !== 'undefined' ? ` ${this.$options.filters.toEur(values[1])}` : ''

      return `${this._translate(computedTitle)}: ${this.translateSentence(values[0])}${cost}`
    },
    _translate (key) {
      return s._translates(key)
    },
    translateSentence (text) {
      return text.split(' ').map(word => this._translate(word)).join(' ')
    },
    enter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        translateX: [20, 0],
        duration: 400,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    leave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        duration: 300,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    decrement () {
      return this.privateState.qty > 0 ? this.privateState.qty-- : null
    },
    increment () {
      return this.privateState.qty < 999 ? this.privateState.qty++ : null
    },
    updateQty () {
      this.$nextTick(function () {
        const form = this.$refs.form

        // Post request
        const settings = {
          url: '/',
          data: h.getFormData(form, s.state.csrf),
          method: 'POST',
          headers: {
            'Content-type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest'
          }
        }

        const locale = document.documentElement.getAttribute('lang')

        axios(settings)
          .then(() => {
            axios
              .get('/' + locale + '/api/v1/cart.json')
              .then(response => {
                const data = response.data.data[0]
                // Put the whole thing into the state
                s.setCartAction(data)
              })
          })
          .catch(function (error) {
            if (s.debug) console.log(error)
          })
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.side-item {
  &__title {
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 1rem;

    @media screen and (max-width: 60em) {
      font-size: 2.25rem;
    }
  }

  &__subtitle {
    line-height: 1;
    margin-bottom: 1rem;
    color: #fff;
  }

  &__options {
    margin-bottom: 1rem;

    > span {
      display: flex;
      justify-content: flex-end;
      align-content: baseline;
      flex-wrap: wrap;
    }
  }

  &__option {
    display: block;
    text-transform: lowercase;
    color: #fff;
    line-height: 1.5;

    &:not(:first-of-type) {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 60em) {
    margin-bottom: 2.5rem;
  }
}
.option{
  font-weight: normal;
  line-height: 16px;
  &__name{
    font-size: 20px;
  }
  &__value{
    font-size: 16px;
  }
}

.faux-text {
  float: right;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faux-text:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #222;
  // background-image: -webkit-linear-gradient(
  //   left,
  //   #151515 0%,
  //   #222 20%,
  //   #151515 50%,
  //   #151515 100%
  // );
  // background-image: linear-gradient(
  //   left,
  //   #151515 0%,
  //   #222 20%,
  //   #151515 50%,
  //   #151515 100%
  // );
  // animation-duration: 2s;
  // animation-fill-mode: forwards;
  // animation-iteration-count: infinite;
  // animation-name: placeHolderShimmer;
  // animation-timing-function: linear;
}

// @-webkit-keyframes placeHolderShimmer {
//   0% {
//     background-position: -468px 0;
//   }
//   100% {
//     background-position: 468px 0;
//   }
// }

// @keyframes placeHolderShimmer {
//   0% {
//     background-position: -468px 0;
//   }
//   100% {
//     background-position: 468px 0;
//   }
// }
</style>
