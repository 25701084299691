<template><div /></template>

<script>
import s from '../store'

export default {
  name: 'StateLoader',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  beforeMount () {
    s.loadData(this.data)
  }
}
</script>
