<template>
  <div class="product__expand">
    <div
      :class="{'product__expand-title_show': isExpanded}"
      class="product__expand-title"
      @click="toggle"
    >
      {{ title }}
      <div v-if="notification" class="product__expand-notification">
        {{ notification }}
      </div>
    </div>
    <div
      v-show="isExpanded"
      class="product__expand-content"
    >
      <slot />
    </div>
    <div v-if="selected" class="product__expand-selected">
      <span v-if="showName">{{ customText ? customText : selected.title }}</span>
      {{ selected.vPriceModificator|toEur }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductExpand',
  props: {
    showName: {
      type: Boolean,
      default: false
    },
    customText: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    selected: {
      type: Object,
      default: null
    },
    expanded: {
      type: Boolean,
      default: false
    },
    notification: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      isExpanded: this.expanded
    }
  },
  methods: {
    toggle () {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>

<style scoped lang="scss">
.product__expand {
  position: relative;
  border-bottom: 1px solid #333333;
  margin-bottom: 1.5rem;

  &-content {
    padding-bottom: 2rem;
  }

  &-selected {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &-notification {
    margin-left: 2rem;
    font-size: 1rem;
    font-family: "FuturaPT", sans-serif;
    color: #ffd600;
  }

  &-title {
    cursor: pointer;
    font-family: Ladislav, serif;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    padding: 0 0 2rem;
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: "";
      width: 0.9375rem;
      height: 0.5rem;
      background-size: cover;
      display: inline-block;
      transition: 0.3s;
      position: absolute;
      right: 0;
      top: 0.55rem;
    }

    &_show {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }
}
</style>
