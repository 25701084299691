<template>
  <div class="product__cuts-out">
    <product-cut-out-option
      v-for="cutout in items"
      :key="cutout.id"
      :disabled="disabled"
      :option="cutout"
    />
  </div>
</template>

<script>
import ProductCutOutOption from './ProductCutOutOption.vue'
import store from '../store'

export default {
  name: 'ProductCutOuts',
  components: {
    ProductCutOutOption
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    disabled: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      state: store.state
    }
  },
  computed: {
  },
  beforeMount () {
    // this.state.selectedOptions.v_depth
  },
  methods: {
    clickHandler () {
      return !this.disabled
    }
  }
}
</script>

<style scoped lang="scss">
.product__cuts-out {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
