<template>
  <div class="relative">
    <ValidationProvider
      v-if="includes(['text', 'email', 'tel', 'password', 'search', 'number'], type)"
      v-slot="{ errors }"
      mode="lazy"
      :vid="name"
      :name="label"
      :rules="validation"
      tag="div"
    >
      <input
        :id="name"
        v-model="currentValue"
        class="relative bg-transparent z-1 input-reset w-100 f5 outline-0 white bb bt-0 bl-0 br-0 br0"
        :style="styles"
        :class="[errors.length ? 'b--red' : !currentValue ? 'b--white' : 'b--green', disabled ? 'o-30' : null]"
        :type="type"
        :placeholder="[validation && validation.includes('required') ? `${placeholder} *` : placeholder]"
        :autocomplete="autocomplete"
        :value="currentValue"
        :name="name"
        :readonly="readonly === 'true'"
        :disabled="disabled"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
      >
      <transition :css="false" @enter="errorEnter" @leave="errorLeave">
        <span v-if="errors.length" class="bg-near-black z-2 red absolute top-4 right-0 pa1 form-error">
          {{ errors[0] }}
        </span>
      </transition>
    </ValidationProvider>
    <ValidationProvider
      v-if="type === 'textarea'"
      v-slot="{ errors }"
      mode="lazy"
      :vid="name"
      :name="label"
      :rules="validation"
      tag="div"
    >
      <textarea
        :id="name"
        v-model="currentValue"
        :style="styles"
        class="input-reset br0 relative bg-transparent z-1 input-reset w-100 f5 outline-0 white b--white bb bt-0 bl-0 br-0"
        :class="[errors.length ? 'b--red' : !currentValue ? 'b--white' : 'b--green', disabled ? 'o-30' : null]"
        rows="8"
        :placeholder="placeholder"
        :name="name"
        :disabled="disabled"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
      />
      <transition :css="false" @enter="errorEnter" @leave="errorLeave">
        <span v-if="errors.length" class="bg-near-black z-2 red absolute top-4 right-0 pa1 form-error">
          {{ errors[0] }}
        </span>
      </transition>
    </ValidationProvider>

    <label v-if="label" :for="name">
      {{ label }}
      <span v-if="validation && validation.includes('required')"> *</span>
    </label>
  </div>
</template>

<script>
import _includes from 'lodash/includes'
import anime from 'animejs'

export default {
  name: 'ViempieField',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      required: true
    },
    initialvalue: {
      type: [String, Number],
      default: null
    },
    autocomplete: {
      type: String,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    scope: {
      type: String,
      default: ''
    },
    validation: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: String,
      default: 'false'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    styles: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      currentValue: null
    }
  },
  computed: {},
  watch: {
    currentValue (value) {
      this.$emit('change', value)
    }
  },
  mounted () {
    if (!this.initialvalue) return
    this.setCurrentValue(this.initialvalue)
    this.$el.classList.add('float-label')
  },
  methods: {
    includes: (collection, value) => _includes(collection, value),
    errorEnter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        translateX: [20, 0],
        duration: 400,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    errorLeave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        duration: 200,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    handleFocus (event) {
      this.$emit('focus', event)
    },
    handleBlur (event) {
      this.$emit('blur', event)
    },
    handleInput (event) {
      const value = event.target.value
      this.$emit('input', value)
      this.$emit('change', value)
      this.setCurrentValue(value)

      if (value) {
        this.$el.classList.add('float-label')
      } else {
        this.$el.classList.remove('float-label')
      }
    },
    setCurrentValue (val) {
      if (val === this.currentValue) return
      this.currentValue = val
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  label {
    font-size: 1rem;
    opacity: 0;
    transform: translateY(5px);
    transition: all 200ms;
    position: absolute;
    top: -1.5rem;
    left: 0;
  }

  .float-label label {
    opacity: 0.9;
    transform: translateY(0);
  }
</style>
