<template>
  <div class="checkout__checkout-sum">
    <div class="checkout__checkout-row">
      <div class="checkout__checkout-row-title">
        {{ _translate('Subtotal') }}
      </div>
      <div class="checkout__checkout-row-value" v-html="selfSubtotal" />
    </div>
    <div
      v-for="(product, n) in selfProducts"
      :key="n"
      class="checkout__checkout-row"
    >
      <div class="checkout__checkout-row-title">
        {{ product.title }}
      </div>
      <div class="checkout__checkout-row-value" v-html="product.value" />
    </div>
    <div class="checkout__checkout-row checkout__checkout-row_total">
      <div class="checkout__checkout-row-title">
        {{ _translate('Total') }}
      </div>
      <div class="checkout__checkout-row-value" v-html="selfTotal" />
    </div>
    <voucher-component :coupon="coupon" @updateCart="updateCart" />

    <div v-if="$slots">
      <slot />
    </div>
  </div>
</template>

<script>
import store from '../store'
import VoucherComponent from './VoucherComponent.vue'

export default {
  name: 'CheckoutCart',
  components: { VoucherComponent },
  props: {
    subtotal: {
      type: String,
      required: true
    },
    products: {
      type: Array,
      required: false,
      default: () => []
    },
    total: {
      type: String,
      required: true
    },
    coupon: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      selfSubtotal: '',
      selfProducts: [],
      selfTotal: 0,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    isMobile: function () {
      return this.windowWidth < 920
    }
  },
  mounted () {
    this.init()

    const Footer = document.querySelector('footer')
    if (window.innerWidth < 920) {
      Footer.style.display = 'none'
    }

    window.onresize = () => {
      setTimeout(() => {
        this.windowWidth = window.innerWidth
        if (this.windowWidth < 920) {
          Footer.style.display = 'none'
        } else {
          Footer.style.display = 'block'
        }
      }, 300)
    }
  },
  methods: {
    updateCart (cart) {
      const products = []
      const toEur = this.$options.filters.toEur
      Object.keys(cart.adjustments).forEach((adjustmentName) => {
        cart.adjustments[adjustmentName].forEach((discount) => {
          const existsDiscount = products.find((product) => product.title === discount.name)

          if (typeof existsDiscount === typeof undefined) {
            products.push({
              title: discount.name,
              value: discount.amount
            })
          } else {
            existsDiscount.value = existsDiscount.value + discount.amount
          }
        })
      })

      this.init(toEur(cart.itemSubtotal),
        products.map((product) => {
          return {
            title: product.title,
            value: toEur(product.value)
          }
        }),
        toEur(cart.totalPrice))
    },
    _translate (key) { return store._translates(key) },
    init (subtotal = this.subtotal, products = this.products, total = this.total) {
      this.selfSubtotal = subtotal
      this.selfProducts = products
      this.selfTotal = total
    }
  }
}
</script>

<style scoped>
button{
  width: 100%;
  margin: 20px 0 0;
}
</style>
