<template>
  <div class="product__v_corners">
    <div
      v-for="corner in items"
      :key="corner.id"
      class="product__corner"
      :style="computedStyle(corner)"
      :class="{'product__corner_active': isActive(corner.id)}"
      @click="changeValue(corner)"
    >
      <div class="product__corner-title">
        {{ corner.title }}
      </div>
      <div class="product__corner-cost">
        {{ corner.vPriceModificator | toEur }}
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'

export default {
  name: 'ProductCorners',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    store
  }),

  methods: {
    isActive (cornerId) {
      return Object.prototype.hasOwnProperty.call(store.state.selectedOptions, 'v_corners') &&
        store.getSelectedOption('v_corners').id === cornerId
    },
    changeValue (corner) {
      this.$emit('costChange', 'v_corners', corner)
    },
    image (corner) {
      if (corner.cover.length) return corner.cover[0]
      return ''
    },
    computedStyle (corner) {
      return {
        backgroundImage: `url('${this.image(corner)}')`
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .product {
    &__corner {
      cursor: pointer;
      border-radius: 2px;
      border: solid 2px #333333;
      transition: 0.3s;
      background-position: left bottom;
      background-size: 50%;
      background-repeat: no-repeat;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
      padding: 0 1.5rem;
      height: 84px;

      &-title {
        line-height: 1.25;
        color: #fff;
        transition: 0.3s;
        max-width: 42%;
      }

      &-cost {
        font-size: 14px;
      }

      &:hover {
        border-color: #616161;

        .product__corner-title {
          color: #fff;
        }
      }

      &_active {
        border-color: #ffd600;

        &:hover {
          border-color: #ffd600;
        }

        .product__corner-title {
            color: #fff;
        }
      }
    }

    &__v_corners {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
</style>
