<template>
  <form class="addresses-form" method="post">
    <input type="hidden" name="action" value="commerce/cart/update-cart">
    <input type="hidden" name="redirect" :value="checkoutUrl">
    <input type="hidden" :name="csrfParam" :value="csrfToken">
    <h4 class="addresses-form__title">
      {{ _translate('Shipping address') }}
    </h4>
    <div class="addresses-form__radio-buttons">
      <div class="addresses-form__radio-button">
        <radio-button
          :title="_translate('Private')"
          :model-value="isForBusiness"
          value="false"
          name="isForBusiness"
          @change="isForBusiness = 'false'"
        />
      </div>
      <div class="addresses-form__radio-button">
        <radio-button
          :title="_translate('For business')"
          :model-value="isForBusiness"
          value="true"
          name="isForBusiness"
          @change="isForBusiness = 'true'"
        />
      </div>
    </div>
    <template v-if="isForBusiness === 'true'">
      <div class="addresses-form__row">
        <input-component
          v-model="$v.businessName.$model"
          name="shippingAddress[businessName]"
          class="addresses-form__input"
          type="text"
          :invalid="$v.businessName.$invalid && $v.businessName.$dirty"
          :success="!$v.businessName.$invalid && $v.businessName.$dirty"
          :placeholder="_translate('Business Name')"
        >
          <template v-if="$v.businessName.$invalid && $v.businessName.$dirty">
            {{ _translate('This field is required') }}
          </template>
        </input-component>
      </div>
      <div class="addresses-form__row">
        <input-component
          v-model="$v.businessTaxId.$model"
          name="shippingAddress[businessTaxId]"
          class="addresses-form__input"
          type="text"
          :required="true"
          :invalid="$v.businessTaxId.$invalid && $v.businessTaxId.$dirty"
          :success="!$v.businessTaxId.$invalid && $v.businessTaxId.$dirty"
          :placeholder="_translate('Business Tax ID')"
        >
          <template v-if="$v.businessTaxId.$invalid && $v.businessTaxId.$dirty">
            {{ _translate('This field is required') }}
          </template>
        </input-component>
        <input-component
          v-model="$v.businessId.$model"
          name="shippingAddress[businessId]"
          class="addresses-form__input"
          type="text"
          :invalid="$v.businessId.$invalid && $v.businessId.$dirty"
          :success="!$v.businessId.$invalid && $v.businessId.$dirty"
          :placeholder="_translate('Chamber of Commerce Number')"
        >
          <template v-if="$v.businessId.$invalid && $v.businessId.$dirty">
            {{ _translate('This field is required') }}
          </template>
        </input-component>
      </div>
    </template>
    <div class="addresses-form__row">
      <input-component
        v-model="$v.firstName.$model"
        name="shippingAddress[firstName]"
        class="addresses-form__input"
        type="text"
        :required="true"
        :invalid="$v.firstName.$invalid && $v.firstName.$dirty"
        :success="!$v.firstName.$invalid && $v.firstName.$dirty"
        :placeholder="_translate('First Name')"
      >
        <template v-if="$v.firstName.$invalid && $v.firstName.$dirty">
          {{ _translate('This field is required') }}
        </template>
      </input-component>
      <input-component
        v-model="$v.lastName.$model"
        name="shippingAddress[lastName]"
        class="addresses-form__input"
        type="text"
        :required="true"
        :invalid="$v.lastName.$invalid && $v.lastName.$dirty"
        :success="!$v.lastName.$invalid && $v.lastName.$dirty"
        :placeholder="_translate('Last Name')"
      >
        <template v-if="$v.lastName.$invalid && $v.lastName.$dirty">
          {{ _translate('This field is required') }}
        </template>
      </input-component>
    </div>
    <div class="addresses-form__row">
      <input-component
        v-model="$v.address.$model"
        name="shippingAddress[address1]"
        class="addresses-form__input"
        type="text"
        :required="true"
        :invalid="$v.address.$invalid && $v.address.$dirty"
        :success="!$v.address.$invalid && $v.address.$dirty"
        :placeholder="_translate('Address')"
      >
        <template v-if="$v.address.$invalid && $v.address.$dirty">
          {{ _translate('This field is required') }}
        </template>
      </input-component>
    </div>
    <div class="addresses-form__row">
      <input-component
        v-model="$v.zip.$model"
        name="shippingAddress[zipCode]"
        class="addresses-form__input"
        type="text"
        :required="true"
        :invalid="$v.zip.$invalid && $v.zip.$dirty"
        :success="!$v.zip.$invalid && $v.zip.$dirty"
        :placeholder="_translate('Zip Code')"
      >
        <template v-if="$v.zip.$invalid && $v.zip.$dirty">
          {{ _translate('This field is required') }}
        </template>
      </input-component>
      <input-component
        v-model="$v.city.$model"
        name="shippingAddress[city]"
        class="addresses-form__input"
        type="text"
        :required="true"
        :invalid="$v.city.$invalid && $v.city.$dirty"
        :success="!$v.city.$invalid && $v.city.$dirty"
        :placeholder="_translate('City')"
      >
        <template v-if="$v.city.$invalid && $v.city.$dirty">
          {{ _translate('This field is required') }}
        </template>
      </input-component>
    </div>
    <div class="addresses-form__row">
      <select-component
        v-model="$v.countryId.$model"
        name="shippingAddress[countryId]"
        class="addresses-form__input"
        type="text"
        :options="_countries()"
        :required="true"
        :init="!$v.countryId.$dirty"
        :invalid="$v.countryId.$invalid && $v.countryId.$dirty"
        :success="!$v.countryId.$invalid && $v.countryId.$dirty"
        :placeholder="_translate('Country')"
      >
        <template v-if="$v.countryId.$invalid && $v.countryId.$dirty">
          {{ _translate('This field is required') }}
        </template>
      </select-component>
    </div>
    <div class="addresses-form__row">
      <input-component
        v-model="state"
        name="shippingAddress[stateValue]"
        class="addresses-form__input"
        type="text"
        :placeholder="_translate('State')"
      />
    </div>
    <div>
      <h4 class="addresses-form__title">
        {{ _translate('Billing address') }}
      </h4>
      <div class="addresses-form__row">
        <input-component
          v-model="$v.b_address.$model"
          name="billingAddress[address1]"
          class="addresses-form__input"
          type="text"
          :required="true"
          :invalid="$v.b_address.$invalid && $v.b_address.$dirty"
          :success="!$v.b_address.$invalid && $v.b_address.$dirty"
          :placeholder="_translate('Address')"
        >
          <template v-if="$v.b_address.$invalid && $v.address.$dirty">
            {{ _translate('This field is required') }}
          </template>
        </input-component>
      </div>
      <div class="addresses-form__row">
        <input-component
          v-model="$v.b_zip.$model"
          name="billingAddress[zipCode]"
          class="addresses-form__input"
          type="text"
          :required="true"
          :invalid="$v.b_zip.$invalid && $v.b_zip.$dirty"
          :success="!$v.b_zip.$invalid && $v.b_zip.$dirty"
          :placeholder="_translate('Zip Code')"
        >
          <template v-if="$v.b_zip.$invalid && $v.b_zip.$dirty">
            {{ _translate('This field is required') }}
          </template>
        </input-component>
        <input-component
          v-model="$v.b_city.$model"
          name="billingAddress[city]"
          class="addresses-form__input"
          type="text"
          :required="true"
          :invalid="$v.b_city.$invalid && $v.b_city.$dirty"
          :success="!$v.b_city.$invalid && $v.b_city.$dirty"
          :placeholder="_translate('City')"
        >
          <template v-if="$v.b_city.$invalid && $v.city.$dirty">
            {{ _translate('This field is required') }}
          </template>
        </input-component>
      </div>
      <div class="addresses-form__row">
        <select-component
          v-model="$v.b_countryId.$model"
          name="billingAddress[countryId]"
          class="addresses-form__input"
          type="text"
          :options="_countries()"
          :required="true"
          :init="!$v.b_countryId.$dirty"
          :invalid="$v.b_countryId.$invalid && $v.b_countryId.$dirty"
          :success="!$v.b_countryId.$invalid && $v.b_countryId.$dirty"
          :placeholder="_translate('Country')"
        >
          <template v-if="$v.b_countryId.$invalid && $v.b_countryId.$dirty">
            {{ _translate('This field is required') }}
          </template>
        </select-component>
      </div>
      <div class="addresses-form__row">
        <input-component
          v-model="b_state"
          name="billingAddress[stateValue]"
          class="addresses-form__input"
          type="text"
          :placeholder="_translate('State')"
        />
      </div>
    </div>
    <div class="addresses-form__checkboxes">
      <checkbox-component
        v-model="sameAddress"
        name="billingAddressSameAsShipping"
        class="addresses-form__checkbox"
        :title="_translate('Use same address for billing')"
        :value="sameAddress"
      />
      <checkbox-component
        name="registerUserOnOrderComplete"
        class="addresses-form__checkbox"
        :title="_translate('Create account')"
        :value="createAccount"
      />
      <checkbox-component
        name="mailchimpCommerce_optIn"
        class="addresses-form__checkbox"
        :title="_translate('Sign up for our newsletter')"
        :value="newsletter"
      />
    </div>
    <button
      class="addresses-form__submit"
      type="submit"
      :disabled="$v.$invalid"
    >
      {{ _translate('Confirm order') }}
    </button>
  </form>
</template>

<script>
import { required, helpers, minLength } from 'vuelidate/lib/validators'
import RadioButton from './RadioButton.vue'
import InputComponent from './InputComponent.vue'
import SelectComponent from './SelectComponent.vue'
import CheckboxComponent from './CheckboxComponent.vue'
import store from '../store'

let validationRules = {
  firstName: {
    required
  },
  lastName: {
    required
  },
  address: {
    required
  },
  countryId: {
    required
  },
  zip: {
    required
  },
  city: {
    required
  },
  b_address: {
    required
  },
  b_countryId: {
    required
  },
  b_zip: {
    required
  },
  b_city: {
    required
  }
}

export default {
  name: 'AddressesComponent',
  components: {
    RadioButton,
    InputComponent,
    SelectComponent,
    CheckboxComponent
  },
  props: {
    checkoutUrl: {
      type: String,
      required: true
    },
    csrfParam: {
      type: String,
      required: true
    },
    csrfToken: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isForBusiness: 'false',
      businessName: '',
      businessTaxId: '',
      businessId: '',
      firstName: '',
      lastName: '',
      address: '',
      b_address: '',
      zip: '',
      b_zip: '',
      city: '',
      b_city: '',
      state: '',
      b_state: '',
      countryId: '',
      b_countryId: '',
      sameAddress: '',
      createAccount: '',
      newsletter: ''
    }
  },
  methods: {
    _countries () { return store.state.countries },
    _translate (key) { return store._translates(key) }
  },
  validations () {
    if (this.isForBusiness === 'true') {
      const alpha = helpers.regex('alpha', /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/i)
      validationRules = {
        ...validationRules,
        businessName: {
          required,
          minLength: minLength(3)
        },
        businessTaxId: {
          required,
          alpha
        },
        businessId: {
          required
        }
      }
    }

    return validationRules
  }
}
</script>

<style scoped lang="scss">
.addresses {
  &-form {
    margin-top: 2.5rem;

    &__checkboxes {
      margin-bottom: 3rem;
    }

    &__checkbox {
      margin-bottom: 1.375rem;
    }

    &__row {
      width: 100%;
      margin-bottom: 2rem;
      display: flex;
      gap: 1rem;
    }

    &__radio-button {
      margin-right: 1.5rem;
    }

    &__radio-buttons {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 2rem;
    }

    &__title {
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }

    &__input {
      flex-grow: 1;
      position: relative;
    }

    &__submit {
      height: 3rem;
      line-height: 3rem;
      padding: 0 2.5rem;
      background-color: #ffd700;
      border: none;
      outline: none;
      cursor: pointer;
      font-family: FuturaPT, serif;
      margin-bottom: 7.625rem;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
</style>
