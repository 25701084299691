<template class="color-samples-pack">
  <div>
    <section v-if="isMobile && (catalogButton || samplesButton)" class="color-samples-navigation">
      <a v-if="catalogButton" href="#samples-catalog" class="color-samples-navigation-btn">
        <div>
          <p v-if="catalogButton.title" class="serif color-samples-navigation-btn-title">{{ catalogButton.title }}</p>
          <p v-if="catalogButton.description">{{ catalogButton.description }}</p>
        </div>
        <p v-if="catalogButton.price" class="price yellow">€{{ catalogButton.price }},-</p>
      </a>
      <a v-if="samplesButton" href="#samples-variants" class="color-samples-navigation-btn">
        <div>
          <p v-if="samplesButton.title" class="serif color-samples-navigation-btn-title">{{ samplesButton.title }}</p>
          <p v-if="samplesButton.description">{{ samplesButton.description }}</p>
        </div>
        <p v-if="samplesButton.price" class="price yellow">€{{ samplesButton.price }},-</p>
      </a>
    </section>
    <!-- Top section -->
    <section id="samples-catalog" class="color-samples-description">
      <div class="flex justify-center items-center items-start-l flex-column flex-row-l">
        <h2 v-if="isMobile" class="serif color-samples__description-title mb3">
          {{ packProduct.title }}
        </h2>
        <template v-if="mediaPreview">
          <img
            v-if="mediaPreview.fileKind === 'image'"
            class="color-samples-description-media"
            :src="mediaPreview.url"
            alt=""
          >
          <video
            v-if="mediaPreview.fileKind === 'video'"
            class="color-samples-description-media"
            muted
            autoplay
            loop
            playsinline
          >
            <source :type="mediaPreview.mimeType" :src="mediaPreview.url">
          </video>
        </template>
        <div class="color-samples-description-block flex flex-column">
          <h2 v-if="!isMobile" class="serif color-samples__description-title">
            {{ packProduct.title }}
          </h2>
          <p v-if="!isMobile" class="price yellow mt2">
            €{{ packProduct.defaultBasePrice }},-
          </p>
          <expandable-description
            v-if="description"
            :is-description="true"
            :description-text="description"
            btn-label-collapsed="Read more"
            btn-label-expanded="Read less"
          />
          <p v-if="otherProductTypesInCart" class="yellow mb3">
            <strong>{{ _translate("Note: Color swatches cannot be ordered at the same time as other products. For that, please place a new order.") }}</strong>
          </p>
          <div class="flex justify-between" :class="{'order-first': isMobile}">
            <form
              ref="form"
              method="POST"
              @submit.prevent="updateCart"
            >
              <input type="hidden" name="action" value="commerce/cart/update-cart">
              <input
                id="inputPurchasableId"
                type="hidden"
                :name="'purchasables['+packProduct.id+'][id]'"
                :value="packProduct.defaultVariantId"
              >
              <input type="hidden" :name="'purchasables['+packProduct.id+'][qty]'" value="1">
              <button
                :disabled="otherProductTypesInCart"
                :class="{'disabled': otherProductTypesInCart}"
                class="color-samples-pack__buy-btn pointer dim"
              >
                {{ _translate('Buy') }}
              </button>
            </form>
            <p v-if="isMobile" class="price yellow mt2">
              €{{ packProduct.defaultBasePrice }},-
            </p>
          </div>
        </div>
      </div>
    </section>

    <a
      v-if="!isMobile && packBanner"
      href="#samples-variants"
      :style="{ backgroundImage: 'url(' + packBanner.image + ')' }"
      class="color-samples-banner"
    >
      <p v-if="packBanner.title" class="color-samples-banner-title serif">{{ packBanner.title }}</p>
      <p v-if="packBanner.description" class="color-samples-banner-description">{{ packBanner.description }}</p>
      <p v-if="packBanner.price" class="price yellow">€{{ packBanner.price }},-</p>
    </a>

    <!-- Select and order samples -->
    <section v-if="!isMobile" class="color-samples-color-variants">
      <h2 class="color-samples__list-title serif">
        {{ _translate('Select sample per piece') }}
      </h2>
      <ul class="color-samples__list">
        <li v-for="(color, handle) in colors" :key="handle">
          <input
            :id="_translate(color.name)"
            type="radio"
            name="colors"
            :checked="selectedColor !== undefined && selectedColor.handle === handle"
            :value="handle"
            @change="changeColor(color.name, handle); scrollElementInView('color-samples', 300, 'smooth');"
          >
          <label :for="_translate(color.name)">
            <span class="color-outer-circle">
              <span class="color-inner-circle" :style="{backgroundColor: color.hex}" />
            </span>
            <span>{{ _translate(color.name) }}</span>
          </label>
        </li>
      </ul>
    </section>

    <!-- Products & sidebar -->
    <section id="samples-variants" class="color-samples-main-content">
      <div class="flex">
        <div v-if="!isMobile" class="color-samples-sidebar">
          <div class="color-samples-sidebar-section">
            <p class="color-samples-sidebar-title serif">
              {{ _translate('Finish') }}
            </p>
            <ul class="color-samples-filters-materials">
              <li v-for="(name, handle) in materials" :key="handle" class="color-samples-filters-color-item">
                <input
                  :id="'sidebar-' + handle"
                  type="radio"
                  name="sidebar-materials"
                  :value="handle"
                  @change="changeMaterial(name, handle)"
                >
                <label :for="'sidebar-' + handle">
                  <span class="radio-circle" />
                  {{ _translate(name) }}
                </label>
              </li>
            </ul>
          </div>
          <div class="color-samples-sidebar-section">
            <p class="color-samples-sidebar-title serif">
              {{ _translate('Colors') }}
            </p>
            <ul class="color-samples-filters-colors">
              <li v-for="(color, handle) in colors" :key="handle" class="color-samples-filters-colors-item">
                <input
                  :id="'sidebar-' + color.name"
                  type="radio"
                  name="sidebar-colors"
                  :checked="selectedColor !== undefined && selectedColor.handle === handle"
                  :value="handle"
                  @change="changeColor(color.name, handle)"
                >
                <label :for="'sidebar-' + color.name">
                  <span class="color-circle" :style="{backgroundColor: color.hex}" />
                  <span>{{ _translate(color.name) }}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="color-samples-catalog">
          <!-- Search & Filters -->
          <color-samples-filters @resetMaterialFilter="uncheckMaterialInputs" @resetColorFilter="uncheckColorInputs" />
          <!-- Products  -->
          <color-samples-products :products="packColors" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ColorSamplesFilters from './ColorSamplesFilters.vue'
import ColorSamplesProducts from './ColorSamplesProducts.vue'
import store from '../../store'
import h from '../../helpers'
import axios from 'axios'
import { translatesMixin } from '../../mixins/translatesMixin'
import { commonMixin } from '../../mixins/commonMixin'
import { sampleLimiterMixin } from '../../mixins/sampleLimiterMixin'
export default {
  name: 'ColorSamples',
  components: {
    ColorSamplesFilters,
    ColorSamplesProducts
  },
  mixins: [translatesMixin, commonMixin, sampleLimiterMixin],
  props: {
    packColors: {
      type: Object,
      required: true
    },
    packProduct: {
      type: Object,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    materials: {
      type: Object,
      required: true
    },
    mediaPreview: {
      type: Object,
      required: false,
      default: null
    },
    description: {
      type: Object,
      required: false,
      default: null
    },
    packBanner: {
      type: Object,
      required: false,
      default: null
    },
    catalogButton: {
      type: Object,
      required: false,
      default: null
    },
    samplesButton: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      state: store.state
    }
  },
  computed: {
    isMobile () {
      return window.innerWidth < 960
    },
    selectedColor () {
      return store.state.selectedOptions.sampleColor
    }
  },
  methods: {
    changeMaterial (materialName, materialHandle) {
      store.setSelectedOption('sampleMaterial', {
        name: materialName,
        handle: materialHandle
      })
    },
    changeColor (colorName, colorHandle) {
      store.setSelectedOption('sampleColor', {
        name: colorName,
        handle: colorHandle
      })
    },
    uncheckMaterialInputs () {
      const materialInputs = document.querySelectorAll('input[name="materials"]')
      const sidebarMaterialInputs = document.querySelectorAll('input[name="sidebar-materials"]')
      materialInputs.forEach(input => {
        input.checked = false
      })
      sidebarMaterialInputs.forEach(input => {
        input.checked = false
      })
    },
    uncheckColorInputs () {
      const colorInputs = document.querySelectorAll('input[name="colors"]')
      const sidebarColorInputs = document.querySelectorAll('input[name="sidebar-colors"]')
      colorInputs.forEach(input => {
        input.checked = false
      })
      sidebarColorInputs.forEach(input => {
        input.checked = false
      })
    },
    updateCart () {
      const form = this.$refs.form
      const data = h.getFormData(form, store.state.csrf)

      // Post request
      const settings = {
        url: '/',
        data,
        method: 'POST',
        headers: {
          'Content-type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }

      const locale = document.documentElement.getAttribute('lang')
      axios(settings)
        .then(() => {
          axios
            .get('/' + locale + '/api/v1/cart.json')
            .then(response => {
              const data = response.data.data[0]
              // Put the whole thing into the state
              store.setCartAction(data)
              store.toggleSideCartAction()
              store.setCsrf()
            })
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.product__description {
  margin: 16px 0;
}

.order-first {
  order: -1;
}
</style>
