<template>
  <transition :css="false" @enter="enter" @leave="leave">
    <div v-if="selectedSamples.length > 0" class="color-samples-selector">
      <button
        v-if="isMobile"
        class="expand-products"
        :class="{'expanded': expandedProducts}"
        type="button"
        @click="toggleExpandedProducts"
      >
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289L4.29289 11.2929C3.90237 11.6834 3.90237 12.3166 4.29289 12.7071C4.68342 13.0976 5.31658 13.0976 5.70711 12.7071L12 6.41421L18.2929 12.7071C18.6834 13.0976 19.3166 13.0976 19.7071 12.7071C20.0976 12.3166 20.0976 11.6834 19.7071 11.2929L12.7071 4.29289ZM12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071L12 13.4142L18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L12.7071 11.2929Z"
            fill="#ffd600"
          />
        </svg>
      </button>
      <form
        ref="form"
        method="POST"
        @submit.prevent="maybeUpdateCart"
      >
        <input type="hidden" name="action" value="commerce/cart/update-cart">
        <div class="color-samples-selector-info">
          <p class="f5 f3-l serif" :class="{'yellow': selectedSamples.length === sampleLimit }">
            {{ selectedSamples.length }} / {{ sampleLimit }} {{ _translate('Samples') }} {{ _translate('Selected').toLowerCase() }}
            <span v-if="isCartSamplesLimitReached" class="product__promo product__promo_warning">
              {{ sprintf(_translate("Maximum %s samples per order"), sampleLimit) }}
            </span>
          </p>
          <div
            v-if="canAddToCart && !otherProductTypesInCart"
            class="flex flex-column-reverse items-end flex-row-l items-center-l"
          >
            <span class="mt3 mt0-l dn di-l">€{{ totalPrice }},-</span>
            <button
              class="color-samples-selector-add-to-cart black f6 f5-l dn db-l"
              type="submit"
              :disabled="state.cartUpdated"
            >
              {{ _translate('Add to cart') }}
            </button>
          </div>
        </div>
        <div>
          <p v-if="otherProductTypesInCart" class="limit-warning">
            <strong>{{ _translate("Note: Color swatches cannot be ordered at the same time as other products. For that, please place a new order.") }}</strong>
          </p>
          <p v-else-if="canAddToCart && isMaximumSamplesSelected" class="limit-warning">
            <strong>{{ _translate("You're at the sample limit") }}.</strong>
            {{ _translate("Add your selection to the cart to proceed") }}
          </p>
        </div>
        <div class="color-samples-selector-products" :class="{'expanded-products': expandedProducts }">
          <div v-for="(variant, index) in selectedSamples" :key="variant.id" class="color-samples-selector-product">
            <input
              id="inputPurchasableId"
              type="hidden"
              :name="'purchasables['+index+'][id]'"
              :value="variant.id"
            >
            <input type="hidden" :name="'purchasables['+index+'][qty]'" value="1">
            <div
              class="color-samples-selector-product-color"
              :style="[variant.finishType !== null && variant.finishType.id === 66314
                ?
                  {'background-image': 'linear-gradient(27deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%)',
                   backgroundColor: variant.sampleColor.rgb}
                :
                  {backgroundColor: variant.sampleColor.rgb}]"
            />
            <div class="color-samples-selector-product-info">
              <p>{{ variant.title }}</p>
              <p class="grey">
                {{ variant.finishType ? variant.finishType.title : variant.colorCode }}
              </p>
              <button class="color-samples-selector-remove-btn" type="button" @click="removeSelectedSample(variant.id)">
                {{ _translate('Remove') }}
              </button>
            </div>
          </div>
          <div v-for="index in samplesLeft" :key="index" class="color-samples-selector-product">
            <div class="color-samples-selector-product-color empty-product" />
          </div>
        </div>
        <span v-if="canAddToCart" class="di dn-l">€{{ totalPrice }},-</span>
        <button
          v-if="canAddToCart"
          class="color-samples-selector-add-to-cart black f6 f5-l db dn-l"
          type="submit"
          :disabled="state.cartUpdated"
        >
          {{ _translate('Add to cart') }}
        </button>
      </form>
    </div>
  </transition>
</template>

<script>
import store from '../../store'
import h from '../../helpers'
import axios from 'axios'
import { debounce } from 'lodash'
import anime from 'animejs'
import { sampleLimiterMixin } from '../../mixins/sampleLimiterMixin'
import { commonMixin } from '../../mixins/commonMixin'
import { translatesMixin } from '../../mixins/translatesMixin'
export default {
  name: 'ColorSamplesSelector',
  components: {},
  mixins: [translatesMixin, sampleLimiterMixin, commonMixin],
  props: {},
  data () {
    return {
      state: store.state,
      expandedProducts: true,
      limit: sampleLimiterMixin.data.sampleLimit
    }
  },
  computed: {
    selectedSamples () {
      return Object.values(this.state.selectedSamples).sort((current, next) => current.timeSelected - next.timeSelected).slice(0, 3)
    },
    samplesLeft () {
      return this.sampleLimit - this.selectedSamples.length
    },
    totalPrice () {
      let price = 0
      if (this.selectedSamples.length > 0) {
        price = this.selectedSamples[0].price
      }

      return price
    },
    isMobile () {
      return window.innerWidth < 960
    },
    isMaximumSamplesSelected () {
      return this.isSelectedSamplesLimitReached()
    },
    isCartSamplesLimitReached () {
      return this.getNumberSamplesCartWithSelected() >= this.sampleLimit
    },
    canAddToCart () {
      return ((this.getNumberSamplesCartWithSelected()) <= this.sampleLimit)
    }
  },
  mounted () {
    if (!this.isMobile) {
      this.expandedProducts = true
    }

    window.waitForFbqReady(() => {
      window.fbq('track', 'ViewContent', {
        content_name: 'Color samples'
      })
    })

    window.conversionApiEvent('view-content', {
      name: 'Color samples'
    })
  },
  methods: {
    maybeUpdateCart: debounce(function () {
      if (this.isCartAndSelectedAboveLimit()) {
        return
      }

      this.updateCart()
    }, 500),
    removeSelectedSample (itemId) {
      store.setSelectedSample(itemId, null)
    },
    updateCart () {
      this.state.cartUpdated = true
      const form = this.$refs.form
      const data = h.getFormData(form, store.state.csrf)

      // Post request
      const settings = {
        url: '/',
        data,
        method: 'POST',
        headers: {
          'Content-type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }

      const locale = document.documentElement.getAttribute('lang')
      axios(settings)
        .then(() => {
          axios
            .get('/' + locale + '/api/v1/cart.json')
            .then(response => {
              const data = response.data.data[0]
              // Put the whole thing into the state
              store.setCartAction(data)
              store.setSamplesInCart()
              store.toggleSideCartAction()
              store.setCsrf()
              store.state.selectedSamples = {}

              window.waitForFbqReady(() => {
                window.fbq('track', 'AddToCart', {
                  content_category: 'Color sample pack',
                  content_type: 'product',
                  content_name: 'Color samples',
                  content_ids: Object.keys(data.cart.lineItems),
                  currency: data.cart.currency,
                  value: data.cart.totalPrice
                })
              })

              window.conversionApiEvent('add-to-cart', {
                category: 'Color sample pack',
                name: 'Color samples'
              })
            }).finally(() => {
              this.state.cartUpdated = false
            })
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    toggleExpandedProducts () {
      this.expandedProducts = !this.expandedProducts
    },
    enter: (el, done) => {
      anime({
        targets: el,
        translateY: ['1000%', '0%'],
        duration: 300,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    leave: (el, done) => {
      anime({
        targets: el,
        translateY: ['0%', '1000%'],
        duration: 300,
        easing: 'easeOutCubic',
        complete: done
      })
    }
  }
}
</script>

<style scoped lang="scss">
  button.expand-products {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    color: #ffd600;
    transition: all .5s;

    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }

    &.expanded {
      transform: rotate(180deg);
    }
  }

  .expanded-products {
    max-height: 600px;
  }

  .limit-warning {
    color: #B2B2B2;
  }

  .empty-product {
    border: 1px dashed gray;
    border-radius: 4px;
  }
</style>
