<template>
  <div>
    <div class="product__v_finish-header">
      {{ _translate('Finish') }}
    </div>
    <div class="product__v_finish">
      <div
        v-for="finish in items"
        :key="finish.id"
        class="product__finish"
        :class="{'product__finish_active': isActive(finish.id)}"
        @click="changeValue(finish)"
      >
        <div
          class="product__finish-image expand__option"
          :style="computedStyle(finish)"
        />
        <div class="product__finish-title">
          {{ finish.title }}
        </div>
        <div class="product__finish-cost">
          {{ finish.vPriceModificator | toEur }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import { eventBus } from '../eventBus'
import { translatesMixin } from '../mixins/translatesMixin'
import { categoryMixin } from '../mixins/categoryMixin'

export default {
  name: 'ProductFinish',
  mixins: [translatesMixin],
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    store,
    pickedColorSlug: '',
    mattFineFinishUID: categoryMixin.finishGroup.mattFineUID,
    semiGlossFinishUID: categoryMixin.finishGroup.semiGlossUID
  }),
  computed: {
    hasSemiGlossFinishInTheCart () {
      const lineItems = Object.values(this.store.state.cart?.lineItems ?? [])

      return lineItems.filter(item => item?.options?.Finish_UID === this.semiGlossFinishUID).length > 0
    }
  },
  mounted () {
    this.pickedColorSlug = store.getSelectedOption('v_color').slug

    eventBus.$on('cartLoaded', event => {
      const selectedFinish = this.store.getSelectedOption('v_finish')
      if (selectedFinish) {
        return
      }

      // Select the first finish item by default
      let selectedDefaultFinish = null
      if (this.hasSemiGlossFinishInTheCart) {
        selectedDefaultFinish = this.items.find(item => item.uid === this.semiGlossFinishUID)
      } else {
        selectedDefaultFinish = this.items.find(item => item.uid === this.mattFineFinishUID)
      }

      if (selectedDefaultFinish) {
        this.changeValue(selectedDefaultFinish)
      }
    })
  },
  created () {
    eventBus.$on('color-changed', color => {
      this.pickedColorSlug = color.slug
    })
  },
  methods: {
    isActive (finishId) {
      if (Object.prototype.hasOwnProperty.call(store.getSelectedOption(), 'v_finish')) {
        return store.getSelectedOption('v_finish').id === finishId
      }

      return false
    },
    changeValue (finish) {
      this.$emit('costChange', 'v_finish', finish)
    },
    image (finish) {
      if (finish.cover.length) return finish.cover[0]
      return ''
    },
    computedStyle (finish) {
      return {
        backgroundImage: `url('${this.image(finish)}')`,
        backgroundPosition: 'right bottom',
        backgroundSize: 'cover'
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .product {
    &__finish {
      flex-basis: 100%;
      cursor: pointer;
      text-align: center;

      &-image {
        border-radius: 2px;
        border: solid 2px #333333;
        height: 80px;
        margin-bottom: 1rem;
        transition: 0.3s;
      }

      &-title {
        line-height: 1.5;
        color: #fff;
        transition: 0.3s;
      }

      &-cost {
        font-size: 14px;
      }

      &:hover {
        .product__finish-image {
          border-color: #616161;
        }

        .product__finish-title {
          color: #fff;
        }
      }

      &_active {
        .product__finish {
          &-image {
            border-color: #ffd600;
          }

          &-image:hover {
            border-color: #ffd600;
          }

          &-title {
            color: #fff;
          }
        }
      }
    }

    &__v_finish {
      display: flex;
      gap: 1.5rem;

      &-header {
        font-family: Ladislav, serif;
        font-size: 1.5rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #ffffff;
        padding: 2rem 0 1rem;
        position: relative;
        display: flex;
        align-items: center;
      }
    }
  }
</style>
