<template>
  <transition
    :css="false"
    @enter="enter"
    @leave="leave"
  >
    <div v-if="sharedState.showIntroVideo" class="fixed z-max bg-black absolute--fill">
      <button class="bg-transparent pointer bw0 absolute top-2 right-2 outline-0 z-3" @click="close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
        >
          <g fill="#FFFFFF" fill-rule="evenodd" transform="rotate(45 19.328 9.672)">
            <rect width="1" height="33" x="16" />
            <rect
              width="1"
              height="33"
              x="16"
              transform="rotate(-90 16.5 16.5)"
            />
          </g>
        </svg>
      </button>

      <div class="absolute absolute--fill flex justify-center items-center">
        <iframe
          class="w-100 vh-100"
          :src="`https://www.youtube.com/embed/${privateState.videoId}?autoplay=1&showinfo=0&modestbranding=1&rel=0`"
          frameborder="0"
          allowfullscreen
        />
      </div>
    </div>
  </transition>
</template>

<script>
import anime from 'animejs'
import s from '../store'

export default {
  name: 'IntroVideo',
  props: {
    videoId: { type: String, default: '' },
    origin: { type: String, default: '' }
  },
  data () {
    return {
      privateState: {
        videoId: this.videoId,
        origin: this.origin
      },
      sharedState: s.state
    }
  },
  computed: {},
  mounted () {},
  methods: {
    close: () => {
      s.toggleIntroVideoAction()
    },
    enter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        duration: 400,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    leave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        duration: 400,
        easing: 'easeOutCubic',
        complete: done
      })
    }
  }
}
</script>
