<template>
  <div class="mb5 side-item">
    <h3 class="white serif side-item__title">
      <span v-if="!privateState.purchasable" class="faux-text w-60">&nbsp;</span>
      <transition :css="false" @enter="enter" @leave="leave">
        <span v-if="privateState.purchasable">
          <span>{{ privateState.purchasable.title.split('|')[0] }}</span>
        </span>
      </transition>
    </h3>

    <div class="flex">
      <div class="w-75">
        <transition :css="false" @enter="enter" @leave="leave">
          <form
            v-if="privateState.purchasable"
            ref="form"
            method="post"
            action
            accept-charset="UTF-8"
            @submit.prevent="updateQty"
          >
            <input type="hidden" name="action" value="commerce/cart/update-cart">
            <input type="hidden" :name="'lineItems['+id+'][id]'" :value="id">
            <input type="hidden" :name="'lineItems['+id+'][qty]'" :value="privateState.qty">

            <button class="bw0 o-70 glow bg-transparent yellow pointer outline-0" @click="decrement">
              <svg width="12" height="12" style="fill:currentColor">
                <path d="M0 7h12V5H0z" />
              </svg>
            </button>

            <input
              v-model="privateState.qty"
              type="text"
              class="serif bw0 white w2 tc"
              readonly
            >
            <button class="bw0 o-70 glow bg-transparent yellow pointer outline-0" @click="increment">
              <svg width="12" height="12" style="fill:currentColor">
                <path d="M0 5h5V0h2v5h5v2H7v5H5V7H0" />
              </svg>
            </button>
          </form>
        </transition>
      </div>

      <div class="w-25">
        <span v-if="!privateState.purchasable" class="faux-text w-40">&nbsp;</span>
        <transition :css="false" @enter="enter" @leave="leave">
          <p v-if="privateState.purchasable" class="serif">
            <span>{{ salePrice * privateState.qty|toEur }}</span>
          </p>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs'
import axios from 'axios'
import h from '../../helpers'
import s from '../../store'

export default {
  name: 'SideCartItemSamplePack',
  props: {
    id: { type: Number, required: true },
    purchasableId: { type: [String, Number], default: '' },
    qty: { type: Number, default: 0 },
    price: { type: Number, default: 0 },
    salePrice: { type: Number, default: 0 },
    variant: { type: Object, default: () => null }
  },
  data () {
    return {
      privateState: {
        qty: this.qty,
        purchasable: null
      },
      sharedState: s.state
    }
  },
  computed: {
  },
  mounted () {
    if (this.variant !== null) {
      this.privateState.purchasable = this.variant
    } else {
      const locale = document.documentElement.getAttribute('lang')

      axios
        .get(`/${locale}/api/v1/purchasables/${this.purchasableId}.json`)
        .then(response => {
          this.privateState.purchasable = response.data
        })
    }
  },
  methods: {
    _translate (key) {
      return s._translates(key)
    },
    enter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        translateX: [20, 0],
        duration: 400,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    leave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        duration: 300,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    decrement () {
      return this.privateState.qty > 0 ? this.privateState.qty-- : null
    },
    increment () {
      return this.privateState.qty < 999 ? this.privateState.qty++ : null
    },
    updateQty () {
      this.$nextTick(function () {
        const form = this.$refs.form

        // Post request
        const settings = {
          url: '/',
          data: h.getFormData(form, s.state.csrf),
          method: 'POST',
          headers: {
            'Content-type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest'
          }
        }

        const locale = document.documentElement.getAttribute('lang')

        axios(settings)
          .then(() => {
            axios
              .get('/' + locale + '/api/v1/cart.json')
              .then(response => {
                const data = response.data.data[0]
                // Put the whole thing into the state
                s.setCartAction(data)
                s.setSamplesInCart()
              })
          })
          .catch(function (error) {
            if (s.debug) console.log(error)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.side-item {
  &__title {
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 1rem;

    @media screen and (max-width: 60em) {
      font-size: 2.25rem;
    }
  }

  &__subtitle {
    line-height: 1;
    margin-bottom: 1rem;
    color: #fff;
  }

  @media screen and (max-width: 60em) {
    margin-bottom: 2.5rem;
  }
}

.color-square {
  margin-left: auto;
  width: 48px;
  height: 48px;
}

.faux-text {
  float: right;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faux-text:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #222;
}
</style>
