<template>
  <div :class="wrapperClass">
    <viempie-select
      :name="modelName ? modelName + '[countryCode]' : 'countryCode'"
      :blank="_translate('Select')"
      :label="_translate('Country')"
      :placeholder="_translate('Country')"
      :scope="scope"
      :options="countiesJson"
      :initialvalue="countriesInitialValue"
      :disabled="loading"
      validation="required"
      required
      @change="onCountryChange"
    />

    <viempie-select
      v-if="states.length"
      :name="modelName ? modelName + '[administrativeArea]' : 'administrativeArea'"
      :blank="_translate('Select')"
      :label="_translate('State')"
      :placeholder="_translate('State')"
      :options="statesJson"
      validation="required"
      :initialvalue="stateValue"
      required
    />

    <div v-if="loading" class="mb0 relative z-1 cf">
      <div class="absolute top-0 left-0 z-0 o-50 transition dib" style="top:-1.5rem;">
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
import ViempieSelect from './ViempieSelect.vue'
import store from '../store'

export default {
  components: { ViempieSelect },
  props: {
    wrapperClass: {
      type: String,
      default: ''
    },
    scope: {
      type: String,
      required: true
    },
    lang: {
      type: String,
      required: true
    },
    modelName: {
      type: String,
      default: null
    },
    countiesList: {
      type: String,
      default: '[]'
    },
    countriesInitialValue: {
      type: String,
      default: ''
    },
    statesList: {
      type: String,
      default: '[]'
    },
    statesInitialValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      counties: JSON.parse(this.countiesList),
      states: JSON.parse(this.statesList),
      stateValue: this.statesInitialValue,
      loading: false
    }
  },
  computed: {
    countiesJson () {
      return JSON.stringify(this.counties)
    },
    statesJson () {
      return JSON.stringify(this.states)
    }
  },
  methods: {
    _translate (key) {
      return store._translates(key)
    },
    onCountryChange (countryCode) {
      this.states = []
      this.stateValue = ''
      this.loading = true

      fetch('/' + this.lang + '/account/addresses/states?code=' + countryCode, {
        method: 'GET'
      }).then(resp => {
        return resp.text()
      }).then(jsonData => {
        this.states = JSON.parse(jsonData.trim())
        this.loading = false
      }).catch(e => {
        console.log(e)
        this.states = []
        this.loading = false
      })
    }
  }
}
</script>
