<template>
  <div class="addresses-form__radio-buttons">
    <div v-if="!showBusinessOnly" class="addresses-form__radio-button">
      <radio-button
        v-model="isPrivate"
        title="Private"
        value="1"
        name="isForBusiness"
      />
    </div>

    <div id="optionBusiness" class="addresses-form__radio-button">
      <radio-button
        v-model="isPrivate"
        title="For business"
        value="0"
        name="isForBusiness"
      />
    </div>
  </div>
</template>

<script>
import RadioButton from './RadioButton.vue'
export default {
  components: { RadioButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    businessOnly: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isPrivate: {
      get () {
        return this.value ? '1' : '0'
      },
      set (val) {
        this.$emit('input', val === '1')
      }
    },
    showBusinessOnly () {
      return this.businessOnly === 1
    }
  },
  mounted () {
    if (this.showBusinessOnly) {
      this.isPrivate = false
    }
  }
}
</script>
