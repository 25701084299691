<template>
  <form
    method="post"
    :data-vv-scope="scope"
    accept-charset="UTF-8"
    class=""
    @submit.prevent="onSubmit"
  >
    <slot
      :account-type="accountType"
      :is-partner="isPartner"
      :set-account-type="setAccountType"
      :component-key="componentKey"
    />
  </form>
</template>

<script>
export default {
  name: 'RegistrationForm',
  props: {
    scope: {
      type: String,
      required: true
    },
    initialAccountType: {
      type: String,
      default: ''
    }
  },
  emits: ['submit'],
  data () {
    return {
      accountType: this.initialAccountType,
      componentKey: 0
    }
  },
  computed: {
    isPartner () {
      return this.accountType === 'partner'
    }
  },
  methods: {
    onSubmit ($event) {
      this.$emit('submit', {
        data: $event,
        isPartner: this.isPartner
      })
    },
    setAccountType (type) {
      this.componentKey += 1
      this.accountType = type
    }
  }
}
</script>
