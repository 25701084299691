<template>
  <div
    v-if="available"
    class="product__item"
  >
    <div
      v-if="colorAvailable"
      class="product__blocked"
    >
      <span class="product__blocked-content">
        {{ _translate('Not available in this color') }}
      </span>
    </div>

    <div v-if="getPreviewImages.length" class="dark-gray product__preview-image-container">
      <swiper-slider
        class="product__preview-image-slider"
        :thumbs="{ swiper: thumbsSwiper }"
        :navigation="true"
        :keyboard="{ enabled: true, onlyInViewport: true }"
        @swiper="setPreviewSwiper"
        @slideChange="handleSlideChange"
      >
        <swiper-slide
          v-for="(item, index) in (showAllImages ? getPreviewImages : getPreviewImages.slice(0, thumbnailsToShow))"
          :key="index"
        >
          <a
            class="db"
            :href="href"
            :title="title"
            aria-hidden="true"
            tabindex="-1"
          >
            <img
              v-if="isImageLoading"
              src="../../assets/loader.gif"
              alt=""
            >
            <img
              v-show="!isImageLoading"
              :src="item.src"
              :srcset="item.srcset"
              :alt="title"
              class="swiper-lazy v-mid mw-none w-100 lazyload product__image"
              @load="handleImageLoad"
            >
          </a>
        </swiper-slide>
      </swiper-slider>
    </div>

    <div class="product__thumbnails">
      <swiper-slider
        class="product__thumbnails-slider"
        :slides-per-view="'auto'"
        :space-between="8"
        watch-slides-visibility
        watch-slides-progress
        @swiper="setThumbsSwiper"
      >
        <swiper-slide v-for="(image, index) in getThumbnails.slice(0, thumbnailsToShow)" :key="index">
          <div
            class="product__thumbnails-item"
          >
            <img :src="image" alt="">
          </div>
        </swiper-slide>
        <swiper-slide v-if="!showAllImages && getThumbnails.length > thumbnailsToShow">
          <a
            :href="href"
            class="product__thumbnails-item product__thumbnails-show-more"
            @click.prevent
          >
            +{{ getThumbnails.slice(thumbnailsToShow).length }}
            <img :src="getThumbnails[thumbnailsToShow]" alt="">
          </a>
        </swiper-slide>
      </swiper-slider>
    </div>

    <div class="product__info">
      <div class="w-100 w-50-l">
        <h3 class="product__title serif">
          <a :href="href">{{ title }}</a>
        </h3>
        <p v-if="product.vPromo && store.state.isPartner === false" class="product__promo">
          {{ product.vPromo }}
        </p>
        <p class="product__price">
          {{ _translate('From') }}: {{ computedCost | toEur }}
        </p>
      </div>
      <div class="product__meta">
        <category-color-selector
          :colors="colors"
          :product="product"
          :init-color="colors[0]"
          :href="href"
        />
        <a class="product__add-btn fw7 sans-serif br2 black bg-yellow pa2 bw0 pointer f5 outline-0 dim ml3" :href="href">{{ _translate('Buy') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import CategoryColorSelector from './CategoryColorSelector.vue'
import { translatesMixin } from '../mixins/translatesMixin'
import 'flickity-imagesloaded'
import SwiperSlider from './Swiper/SwiperSlider.vue'

export default {
  name: 'MainProductComponent',
  components: {
    CategoryColorSelector,
    SwiperSlider
  },
  mixins: [translatesMixin],
  props: {
    product: {
      type: Object,
      required: true
    },
    configuration: {
      type: Object,
      default: () => { return {} }
    },
    images: {
      type: Object,
      required: true
    },
    colors: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      store,
      disabled: {
        phrase: '',
        key: '',
        value: ''
      },
      imageIndex: 0,
      isImageLoading: false,
      loadingTimeout: null,
      showAllImages: false,
      thumbsSwiper: null,
      previewSwiper: null
    }
  },
  computed: {
    isMobile () {
      return window.innerWidth < 960
    },
    thumbnailsToShow () {
      if (this.showAllImages) {
        return this.getThumbnails.length
      }

      return this.isMobile ? 3 : 7
    },
    getSelectedColor () {
      return store.getSelectedOption('v_color')
    },
    getImagesForSelectedColor () {
      return this.images[this.getSelectedColor.id]
    },
    getPreviewImages () {
      const imagesForSelectedColor = this.getImagesForSelectedColor
      if (
        !imagesForSelectedColor ||
        !Object.prototype.hasOwnProperty.call(imagesForSelectedColor, 'previewImages')
      ) {
        return []
      }

      return imagesForSelectedColor.previewImages
    },
    getThumbnails () {
      const imagesForSelectedColor = this.getImagesForSelectedColor
      if (
        !imagesForSelectedColor ||
        !Object.prototype.hasOwnProperty.call(imagesForSelectedColor, 'thumbnails')
      ) {
        return []
      }

      return imagesForSelectedColor.thumbnails
    },
    // imagesForSelectedColor () {
    //   if (
    //     store.getSelectedOption('v_color') &&
    //     Object.prototype.hasOwnProperty.call(this.images, store.getSelectedOption('v_color').id)
    //   ) {
    //     return this.images[store.getSelectedOption('v_color').id]
    //   } else if (Object.prototype.hasOwnProperty.call(this.images, 'default')) {
    //     return this.images.default
    //   } else {
    //     return this.images[Object.keys(this.images)[0]]
    //   }
    // },
    computedCost () {
      const checkedProperties = ['v_depth', 'v_len']
      let cost = 0

      if (this.product.typeHandle === 'newAccessories') {
        return this.product.defaultPrice
      }
      checkedProperties.forEach((key) => {
        if (!Object.prototype.hasOwnProperty.call(store.state.selectedOptions[key], 'id')) {
          cost += this.configuration[key].vPriceModificator
        }
      })
      for (const key in this.configuration) {
        if (
          ['v_len', 'v_depth', 'v_color'].indexOf(key) === -1 &&
          Object.prototype.hasOwnProperty.call(this.configuration, key)
        ) {
          if (Object.prototype.hasOwnProperty.call(this.configuration[key], 'vPriceModificator')) {
            cost += this.configuration[key].vPriceModificator
          }
        }
      }
      for (const key in store.state.selectedOptions) {
        if (
          ['v_len', 'v_depth', 'v_color'].indexOf(key) !== -1 &&
          Object.prototype.hasOwnProperty.call(store.getSelectedOption(key), 'vPriceModificator')
        ) {
          cost += store.getSelectedOption(key).vPriceModificator
        }
      }
      return cost
    },
    href () {
      let params = '?'
      let properties = ['v_len', 'v_depth']
      if (this.product.typeHandle === 'newAccessories') {
        properties = ['v_depth']
      }

      for (const key in store.state.selectedOptions) {
        if (properties.indexOf(key) !== -1 && store.getSelectedOption(key).id) {
          params += `${key}=${store.getSelectedOption(key).id}&`
        }
      }
      for (const key in this.configuration) {
        if (
          properties.indexOf(key) === -1 &&
          Object.prototype.hasOwnProperty.call(this.configuration, key)
        ) {
          params += `${key}=${this.configuration[key].id}&`
        }
      }
      const hash = JSON.stringify(store.state.additionInfo) === '{}' ? '' : `#${JSON.stringify(store.state.additionInfo).replaceAll(' ', '')}`

      return this.product.vmodelCategory[this.product.vmodelCategory.length - 1] +
          `/${this.product.slug}--${store.getSelectedOption('v_color').slug}${params.slice(0, -1)}${hash}`
    },
    title () {
      return this.product.title.replace(/\| {color.title}/, '')
    },
    available () {
      return !(store.getSelectedOption('v_cutouts').slug &&
          this.configuration.v_cutouts.slug !== store.getSelectedOption('v_cutouts').slug)
    },
    colorAvailable () {
      return !this.colors.find((color) =>
        store.getSelectedOption('v_color').vSkuModificator === color.vSkuModificator)
    }
  },
  watch: {
    showAllImages () {
      this.$nextTick(() => {
        this.updateSwiper()
      })
    },
    getPreviewImages () {
      this.$nextTick(() => {
        this.updateSwiper()
      })
    }
  },
  methods: {
    redirect (url) {
      window.location.href = url
    },
    openAllImages () {
      this.showAllImages = true
    },
    handleImageLoad () {
      clearTimeout(this.loadingTimeout)
      this.isImageLoading = false
    },
    setPreviewSwiper (swiper) {
      this.previewSwiper = swiper
    },
    setThumbsSwiper (swiper) {
      this.thumbsSwiper = swiper
    },
    updateSwiper () {
      if (this.thumbsSwiper) {
        this.thumbsSwiper.update()
      }
      if (this.previewSwiper) {
        this.previewSwiper.update()
      }
    },
    handleSlideChange () {
      if (!this.showAllImages && this.previewSwiper.realIndex === this.previewSwiper.slides.length - 1) {
        this.openAllImages()
        this.updateSwiper()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product {
  &__meta {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 2rem;

    @media screen and (max-width: 60em) {
      align-items: stretch;
      margin-top: -2rem;
    }
  }

  &__preview-image-slider {
    margin-bottom: 1.5rem;

    .product__image {
      height: 215px;

      @media screen and (max-width: 60em) {
        height: 150px;
      }
    }
  }

  &__preview-image-container {
    max-width: 768px;
    display: flex;
    align-items: center;
  }

  &__preview-image-container a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__thumbnails {
    margin: 24px 0;
    display: flex;
    overflow: auto;

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 80px;
      height: 48px;
      border: 2px solid #333;
      cursor: pointer;
      overflow: hidden;
      transition : border 350ms ease-in-out;
    }

    &-item-active {
      border-color: #ffd600;
    }

    &-item:focus {
      outline: none;
    }

    &-show-more img {
      position: absolute;
      opacity: 0.45;
      filter: blur(1px);
      top: 0;
      left: 0;
      z-index: -1;
    }

    &-slider {
      margin-left: 0;
      margin-right: 0;

      .swiper-slide {
        width: auto;

        &.swiper-slide-thumb-active .product__thumbnails-item {
          border-color: #ffd600;
        }
      }
    }
  }

  &__title {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.2;

    a {
      &:hover, &:focus {
        color: #ffd600;
      }
    }
  }

  &__promo {
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffd600;
  }

  &__price {
    margin-top: 0.5rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;

    @media screen and (max-width: 60em) {
      margin-top: 1.5rem;
    }
  }

  &__modification {
    line-height: 1.5rem;
    color: #ffd600;
    margin-bottom: 0.5rem;
  }

  &__colors {
    display: flex;
    gap: 0.75rem;

    @media screen and (max-width: 60em) {
      gap: 0.25rem;
    }
  }

  &__color {
    position: relative;
  }

  &__asterisk {
    position: absolute;
    top: 40%;
    left: 100%;
    font-size: inherit;
  }

  &__blocked {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, .9);
    z-index: 1;

    &-content {
      margin: auto;
    }
  }

  &__show {
    color: #ffd600;
    margin-left: 1rem;
    cursor: pointer;
  }

  &__info {
    position: relative;
    display: flex;

    @media screen and (max-width: 60em) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__item {
    position: relative;
    margin-bottom: 1.5rem;

    &--columns {
      display: flex;
      align-items: flex-start;

      .w-50-l {
        width: 100%;
      }
      .product__info {
        flex-direction: column;
        order: -1;
      }
      .product__meta {
        margin-top: 1.5rem;
        margin-left: 0;
        padding-left: 0;

        @media screen and (max-width: 60em) {
          margin-top: 1rem;
        }
      }
      .product__price {
        @media screen and (max-width: 60em) {
          margin-top: 0.5rem;
        }
      }
    }
  }

  &__add-btn {
    height: 2.5rem;
    flex-shrink: 0;
    min-width: 80px;
    text-align: center;
    white-space: nowrap;
  }
}
</style>
