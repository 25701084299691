<template>
  <label
    class="color-samples-item"
    :class="{ 'variant-disabled': isDisabled }"
    @chage="carrySelectedSamples"
  >
    <span
      class="color-samples-item-color"
      :style="[isSemiGlassMaterial ? {'background-image': 'linear-gradient(27deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%)', backgroundColor: bgColor} : {backgroundColor: bgColor}]"
    >
      <span
        class="flex justify-center items-center color-samples-item-selected"
        :class="{'variant-selected': isSelected, 'black': isLightBg}"
      >
        {{ _translate("Selected") }}
      </span>
    </span>
    <span class="flex justify-between">
      <span class="flex flex-column">
        <span class="f6 color-samples-item-name">{{ variant.title }}</span>
        <span v-if="variant.finishType" class="f6 grey">{{ variant.finishType.title }}</span>
        <span v-else-if="variant.colorCode" class="f6 grey">{{ variant.colorCode }}</span>
      </span>
      <span class="color-samples-item-checkbox">{{ _translate("Add") }}
        <input
          type="checkbox"
          name="selected_color_sample"
          :checked="isSelected"
          :disabled="isDisabled"
          @change="carrySelectedSamples"
        >
        <span
          class="checkmark"
        />
      </span>
    </span>
    <span v-if="variant.note" class="f6 grey font-italic">{{ variant.note }}</span>
  </label>
</template>

<script>
import store from '../../store'
import tinycolor from 'tinycolor2'
import { translatesMixin } from '../../mixins/translatesMixin'
import { sampleLimiterMixin } from '../../mixins/sampleLimiterMixin'

export default {
  name: 'ColorSamplesVariant',
  components: {},
  mixins: [translatesMixin, sampleLimiterMixin],
  props: {
    variant: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      state: store.state,
      selected: false,
      isHoverState: false,
      bgColor: ''
    }
  },
  computed: {
    isDisabled () {
      return this.isSelectedSamplesLimitReached() && !store.getSelectedSample(this.variant.id)
    },
    isSelected () {
      return store.getSelectedSample(this.variant.id)
    },
    isSemiGlassMaterial () {
      return this.variant.finishType !== null && this.variant.finishType.id === 66314
    },
    isLightBg () {
      return tinycolor(this.bgColor).isLight()
    }
  },
  mounted () {
    this.bgColor = this.variant.sampleColor.rgb
  },
  methods: {
    carrySelectedSamples () {
      // Use date to further sort objects by recently selected
      const variant = { ...this.variant, ...{ timeSelected: Date.now() } }
      if (!this.isSelected) {
        store.setSelectedSample(variant.id, variant)
      } else {
        store.setSelectedSample(variant.id, null)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.variant-selected {
  opacity: 1;
}
.variant-disabled {
  user-select: none;
}
</style>
