<template>
  <div class="color-samples-filters-settings">
    <h2 v-if="$parent.isMobile" class="color-samples-search-title serif">
      {{ _translate('Select sample per piece') }}
    </h2>
    <div class="color-samples-search flex flex-column">
      <input
        type="text"
        name="search"
        :placeholder="_translate('Find RAL color')"
        @keyup="searchColorSamples"
      >
      <div v-if="$parent.isMobile" class="color-samples-filters-mobile flex justify-between items-center">
        <p class="serif f3">
          {{ _translate('Colors') }}
        </p>
        <ul class="color-samples-filters-colors">
          <li v-for="(color, handle) in $parent.colors" :key="handle" class="color-samples-filters-colors-item">
            <input
              :id="'filters-' + color.name"
              type="radio"
              name="colors"
              :checked="selectedColor !== undefined && selectedColor.handle === handle"
              :value="handle"
              @change="changeColor(color.name, handle); scrollElementInView('color-samples', 260, 'smooth');"
            >
            <label :for="'filters-' + color.name">
              <span class="color-outer-circle">
                <span class="color-inner-circle" :style="{backgroundColor: color.hex}" />
              </span>
            </label>
          </li>
        </ul>
      </div>
      <div v-if="$parent.isMobile" class="color-samples-filters-mobile flex justify-between items-center">
        <p class="serif f3">
          {{ _translate('Finish') }}
        </p>
        <ul class="color-samples-filters-materials">
          <li v-for="(name, handle) in $parent.materials" :key="handle" class="color-samples-filters-color-item">
            <input
              :id="'filters-' + handle"
              type="radio"
              name="materials"
              :data-name="name"
              :checked="selectedMaterial !== undefined && selectedMaterial.handle === handle"
              :value="handle"
              @change="changeMaterial(name, handle)"
            >
            <label :for="'filters-' + handle">
              <span class="radio-circle" />
              {{ name }}
            </label>
          </li>
        </ul>
      </div>

      <div v-if="selectedMaterial || selectedColor" class="color-samples-filters">
        <h3 class="color-samples-filters-title serif">
          Filters
        </h3>
        <div class="selected-filters">
          <button
            v-if="selectedMaterial"
            @click="removeMaterialFilter"
          >
            <span>{{ _translate(selectedMaterial.name) }}</span>
            <svg
              class="close-icon"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 1.5L1.5 0L5 3.5L8.5 0L10 1.5L6.5 5L10 8.5L8.5 10L5 6.5L1.5 10L0 8.5L3.5 5"
                fill="#B2B2B2"
              />
            </svg>
          </button>
          <button
            v-if="selectedColor"
            @click="removeColorFilter"
          >
            <span>{{ _translate(selectedColor.name) }}</span>
            <svg
              class="close-icon"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 1.5L1.5 0L5 3.5L8.5 0L10 1.5L6.5 5L10 8.5L8.5 10L5 6.5L1.5 10L0 8.5L3.5 5"
                fill="#B2B2B2"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store'
import { translatesMixin } from '../../mixins/translatesMixin'
import { commonMixin } from '../../mixins/commonMixin'

export default {
  name: 'ColorSamplesFilters',
  mixins: [translatesMixin, commonMixin],
  props: {
  },
  data () {
    return {
      state: store.state
    }
  },
  computed: {
    selectedMaterial () {
      return this.state.selectedOptions.sampleMaterial
    },
    selectedColor () {
      return this.state.selectedOptions.sampleColor
    }
  },
  methods: {
    searchColorSamples (e) {
      const searchValue = e.target.value
      if (searchValue.length === 0) {
        store.setSelectedOption('searchValue', null)
      }
      store.setSelectedOption('searchValue', searchValue)
    },
    changeColor (colorName, colorHandle) {
      store.setSelectedOption('sampleColor', {
        name: colorName,
        handle: colorHandle
      })
    },
    changeMaterial (materialName, materialHandle) {
      store.setSelectedOption('sampleMaterial', {
        name: materialName,
        handle: materialHandle
      })
    },
    removeMaterialFilter () {
      store.setSelectedOption('sampleMaterial', null)
      this.$emit('resetMaterialFilter')
    },
    removeColorFilter () {
      store.setSelectedOption('sampleColor', null)
      this.color = null
      this.$emit('resetColorFilter')
    }
  }
}
</script>
