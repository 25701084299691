<template>
  <img
    :src="src"
    :data-src="src"
    :alt="title"
    class="v-mid mw-none w-100 lazyload product__image"
  >
</template>

<script>
export default {
  name: 'ProductImage',
  props: {
    src: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>
